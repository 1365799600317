import React from "react";
import ReactDOM from "react-dom";


import reportWebVitals from "./reportWebVitals";

// For enabling client side routing----
import { BrowserRouter } from "react-router-dom";

//Custom ThemeProvider----
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./config/themes/MainTheme";

import "./index.css";
import App from "./App";

import { Provider } from "react-redux";

import configureStore from "./store/configureStore";
import ErrorBoundaryForApp from "./ErrorHandler/ErrorBoundaryForApp";

const store = configureStore();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <ErrorBoundaryForApp>
            <App />
          </ErrorBoundaryForApp>
        </ThemeProvider>
      </React.StrictMode>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();

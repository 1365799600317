import React, { Fragment } from "react";
import { withRouter } from "react-browser-router";
// import queryString from "query-string";
import DisabledTextField from "./DisabledTextField";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import listTransform from "../../../../../utilities/listTransform";
import SuccessImage from "../../../../../assets/images/success-mandate-reg2.jpg";
import EnachLogo from "../../../../../assets/images/e-mandate-smalllogo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: "30vh",
  },
  greenCheck: {
    color: "green",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  display: {
    display: "block",
  },
}));

function MandateRegistrationSuccess(props) {
  const classes = useStyles();
  // const responseFromBackend = queryString.parse(props.location.search);
  const dataList = [];
  dataList.push(props.apiData);

  const dataTransform = listTransform(dataList, [
    "mandateEndDate",
    "mandateStartDate",
    "mandateRegTime",
    "mandateCreditTime",
  ])[0];

  const dataToShowInStatus = [
    "mandateRegistrationId",
    "clientRegistrationId",
    "regestrationStatus",
  ];
  const dataToShowInStatusMapping = {
    mandateRegistrationId: "Mandate Registration ID",
    clientRegistrationId: "Client Registration ID",
    regestrationStatus: "Status",
  };

  const onClickDone = () => {
    props.apiData.userType === "merchant"
      ? props.history.push("/")
      : props.history.push("/mandateLinkRegistrationComplete");
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        <img src={SuccessImage} alt="SUCCESS"></img>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid
        item
        xs={10}
        container
        direction="row"
        spacing={2}
        className={classes.root}
      >
        {Object.keys(dataTransform).map((key, index) =>
          dataToShowInStatus.includes(key) ? (
            <Grid item xs={4}>
              <DisabledTextField
                label={dataToShowInStatusMapping[key]}
                labelValue={dataTransform[key]}
              />
            </Grid>
          ) : (
            ""
          )
        )}
      </Grid>

      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <CheckCircleRoundedIcon className={classes.greenCheck} />
        <Typography display="inline">
          Your E-Mandate registration is successfully completed.Your Consumer
          Reference No. for this E-Mandate registration is{" "}
          <Box display="inline" fontWeight="fontWeightBold">
            {dataTransform.consumerReferenceNumber}.
          </Box>
        </Typography>
      </Grid>
      {props.apiData.mandateType.toLowerCase() === "ONLINE".toLowerCase() && (
        <Grid item xs={12}>
          <Button variant="outlined" color="primary" onClick={onClickDone}>
            Done
          </Button>
        </Grid>
      )}

      {props.apiData.mandateType.toLowerCase() === "PHYSICAL".toLowerCase() && (
        <Fragment>
          <Grid item xs={3}></Grid>

          <Grid item xs={6}>
            <Button variant="outlined" color="primary" onClick={onClickDone}>
              Done
            </Button>
          </Grid>

          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              props.setToShowPhysicalMandateForm(
                !props.toShowPhysicalMandateForm
              )
            }
          >
            View
          </Button>

          <Grid item xs={3}></Grid>
        </Fragment>
      )}

      <Grid item xs={12}>
        <img src={EnachLogo} alt="LOGO"></img>
      </Grid>
    </Grid>
  );
}

export default withRouter(MandateRegistrationSuccess);

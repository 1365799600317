import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";
// import onlineEmandate from "./onlineEmandate";

const emandateList = {
  //filter List
  // fromDate: "2021-01-01",
  fromDate: new Date().toISOString().slice(0, 10),
  toDate: new Date().toISOString().slice(0, 10),

  mandateList: [],
  cancelMandateList: [],
  debitTransactionList: { records: [], count: 0 },
  pendingMandateList: [],
  pseudoSearchList: [],
  isSearchingState: false,
};

export const fetchEmandateList = createAsyncThunk(
  "emandateFilterList/fetchEmandateList",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterRegistration`,
      {
        fromDate: emandateList.fromDate,
        toDate: emandateList.toDate,
        m_id: requestParam.merchant_id,
        page: 1,
        length: 1000,
      }
    );
    return response.data;
  }
);

export const fetchCancelMandateList = createAsyncThunk(
  "emandateFilterList/fetchCancelMandateList",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/filterregistrationCancellation`,
      {
        fromDate: emandateList.fromDate,
        toDate: emandateList.toDate,
        merchantcode: requestParam.merchant_code,
        page: 1,
        length: 1000,
      }
    );
    return response.data;
  }
);

export const fetchPendingMandateList = createAsyncThunk(
  "emandateFilterList/fetchPendingMandateList",
  async (requestParam) => {
    // console.log("This is the Request", requestParam);
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterRegistration`,
      {
        fromDate: emandateList.fromDate,
        toDate: emandateList.toDate,
        m_id: requestParam.merchant_id,
        page: 1,
        length: 1000,
      }
    );
    // console.log("This is the Reesponse", response);
    return response.data.records.filter(
      (item) =>
        item.bankName === null || item.regestrationStatus === "INITIATED"
    );
  }
);

export const fetchDebitTransactionList = createAsyncThunk(
  "emandateFilterList/fetchDebitTransactionList",
  async (requestParam) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterTransaction`,
      {
        fromDate: yesterday.toISOString().slice(0, 10),
        // fromDate: "2019-01-01",
        toDate: emandateList.toDate,
        m_id: requestParam.merchant_id,
        page: 1,
        length: 1000,
      }
    );
    return response.data;
  }
);

const emandateListSlice = createSlice({
  name: "emandateList",
  initialState: emandateList,
  reducers: {
    emandateTabSwitchStateReset: (emandateList, action) => {
      emandateList.isSearchingState = false;
      emandateList.pseudoSearchList = [];
    },
    allFormSubmitted: (emandateList, action) => {
      // onlineEmandate.forms = action.payload.formDetails;
    },
    isSearching: (emandateList, action) => {
      emandateList.isSearchingState = action.payload.isSearchingState;
    },
    pseudoSearchListSet: (emandateList, action) => {
      //payload:{searchValue:"dsa",listToSearch:[{},{}]}
      try {
        let searchList = [];
        action.payload.listToSearch.map((emandate) => {
          let isSearchValuePresent = Object.keys(emandate).some(
            (emandateKey, index) => {
              if (
                emandate[emandateKey] !== null &&
                !Array.isArray(emandate[emandateKey])
              ) {
                if (
                  emandate[emandateKey]
                    .toString()
                    .toLowerCase()
                    .indexOf(action.payload.searchValue.toLowerCase()) !== -1
                ) {
                  return true;
                }
              }
            }
          );

          if (isSearchValuePresent) {
            searchList.push(emandate);
          }
        });
        emandateList.pseudoSearchList = searchList;
      } catch (err) {}
    },
  },
  extraReducers: {
    //fetchEmandateList
    [fetchEmandateList.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchEmandateList.fulfilled]: (state, action) => {
      state.mandateList = action.payload.records;
    },
    [fetchEmandateList.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //fetchCancelMandateList
    [fetchCancelMandateList.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchCancelMandateList.fulfilled]: (state, action) => {
      state.cancelMandateList = action.payload;
    },
    [fetchCancelMandateList.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //fetchPendingMandateList
    [fetchPendingMandateList.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchPendingMandateList.fulfilled]: (state, action) => {
      state.pendingMandateList = action.payload;
    },
    [fetchPendingMandateList.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //fetchDebitTransactionList
    [fetchDebitTransactionList.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchDebitTransactionList.fulfilled]: (state, action) => {
      state.debitTransactionList = action.payload;
    },
    [fetchDebitTransactionList.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

// Exporting onlineEmandate actions
export const {
  allFormSubmitted,
  isSearching,
  pseudoSearchListSet,
  emandateTabSwitchStateReset,
} = emandateListSlice.actions;

// Exporting slice reducer
export default emandateListSlice.reducer;

import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../../TextError/TextError";
import '../Input-Input/Input-Input.css'
import { values } from "lodash";

export default function MobileField(props) {
  const { label,name,name1, name2,option, field2MaxLength, ...rest } = props;
  return (
    <div className="form__control">
      <label htmlFor={name}>{label}</label>
      <div className="form__control__field__div">
        <Field 
          className='input__field1' 
          placeholder='91' 
          name={name1} 
          id={name1}
          disabled
          // {...rest} 
          />
        <Field 
           render={({ field,form,meta/* { name, value, onChange, onBlur } */ }) => {
             return(
            <input  name={name2} id={name2} {...rest}  maxlength={field2MaxLength} className='input__field2'
            value={field.value.mobileNumber.number}
            onChange={
              (e)=>(isNaN(e.target.value)?null:form.setFieldValue(name2,e.target.value))
            }
            />
             )

           }}
        />
      </div>
      <ErrorMessage name={name1} component={TextError} />
      <ErrorMessage name={name2} component={TextError} />
    </div>
  );
}
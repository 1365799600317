import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

const uiState = {
  backDropWithProgress: false,
  showConfirmationDialog: false,
};

export const fetchRandomApi = createAsyncThunk(
  "selectedView/fetchRandomApi",
  async () => {
    const response = await Axios.get(
      "https://jsonplaceholder.typicode.com/posts"
    );
    return response.data;
  }
);

// viewSelected Slice----

const uiStateSlice = createSlice({
  name: "uiState",
  initialState: uiState,
  reducers: {
    settingBackDropWithProgress: (uiState, action) => {
      uiState.backDropWithProgress = action.payload.backDropWithProgress;
    },
    settingConfirmationDialog: (uiState, action) => {
      uiState.showConfirmationDialog = action.payload.showConfirmationDialog;
    },
  },
  extraReducers: {},
});

// Exporting uiState actions
export const {
  settingBackDropWithProgress,
  settingConfirmationDialog,
} = uiStateSlice.actions;

// Exporting slice reducer
export default uiStateSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";

const MisReport = {
  MisReportData: [],
  status: "",
  error: "",
};

export const getFilteredDataForMisReport = createAsyncThunk(
  "MisReport/getFilteredDataForMisReport",
  async (reqParams) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/ViewMISReport`,
      {
        p_merchant_code: `${reqParams.merchantSelect.value}`,
        p_from_date: reqParams.startDate.value,
        p_to_date: reqParams.endDate.value,
        p_payment_status: reqParams.PaymentStatus.value,
      }
    );
    return response.data;
  }
);

// viewSelected Slice----

const MisReportSlice = createSlice({
  name: "invoice",
  initialState: MisReport,
  reducers: {
    MisReportList: (MisReport, action) => {
      const data = MisReport.MisReportData;
      return data;
    },
    clearMisReport: (MisReport, action) => {
      MisReport.MisReportData = [];
    },
  },
  extraReducers: {
    [getFilteredDataForMisReport.pending]: (state, action) => {
      state.status = "pending";
    },
    [getFilteredDataForMisReport.fulfilled]: (state, action) => {
      state.MisReportData = action.payload;
    },
    [getFilteredDataForMisReport.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

// Exporting onlineEmandate actions
export const { MisReportList, clearMisReport } = MisReportSlice.actions;

// Exporting slice reducer
export default MisReportSlice.reducer;

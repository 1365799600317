import React from "react";
import { makeStyles } from "@material-ui/core/styles";

//Components form material ui--

import { Drawer, Toolbar } from "@material-ui/core";

// import PaymentIcon from "@material-ui/icons/Payment";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { withRouter } from "react-browser-router";
import SideDrawerItems from "./components/SideDrawerItems";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerContainer: {
    display: "flex",

    overflow: "none",
    // this  was for the spacing of sidedrawe items
    "& .MuiListItem-button": {
      marginBottom: "5px",
    },
  },
  // drawerItems:{
  //   marginBottom:'5px'
  // },
  drawerPaper: {
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const SideDrawerForLargeScreen = (props) => {
  const classes = useStyles();

  return (
    <Drawer
      transitionDuration={10}
      className={classes.drawer}
      variant="permanent"
      elevation={10}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <SideDrawerItems />
      </div>
    </Drawer>
  );
};

export default withRouter(SideDrawerForLargeScreen);

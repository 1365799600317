import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";

const Settelments = {
  reportData: [],
  allSettlements: {},
  SettelmentReportsClientList: [],

  SettelmentTableview: false,
  status: "",
  error: "",
};

export const SaveSettlement = createAsyncThunk(
  "Settlement/SaveSettlements",
  async (requestParam) => {
    const request = {
      p_settlement_amount: requestParam.data.settlement_amount,
      p_settlement_bank_ref: requestParam.data.settlement_bank_ref,
      p_settlement_date: requestParam.data.settlement_date,
      p_settlement_utr: requestParam.data.settlement_utr,
      p_txn_id: requestParam.data.txn_id,
      p_settlement_by: requestParam.user,
    };
    const response = await Axios.post(
      `${config.backendUrl}/npci/SaveSettlement`,
      request
    );
    return response.data;
  }
);

export const SetelmentsReportDropdown = createAsyncThunk(
  "Settlement/SettelmentsReport/ClientList Dropdown",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/getClientList`
    );
    return response.data;
  }
);
export const SettelmentReportsData = createAsyncThunk(
  "Settlement/SettelmentReportsData",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/ViewSettlementReport`,
      requestParam
    );

    return response.data;
  }
);
// viewSelected Slice----

const SettelmentsSlice = createSlice({
  name: "userManagement",
  initialState: Settelments,
  reducers: {
    SettelmentTableview: (Settelments, action) => {
      Settelments.SettelmentTableview = action.payload;
    },
  },

  extraReducers: {
    ///For Saving Settelements---
    [SaveSettlement.pending]: (Settelments, action) => {
      Settelments.status = "pending";
    },
    [SaveSettlement.fulfilled]: (Settelments, action) => {
      Settelments.allSettlements = action.payload;
    },
    [SaveSettlement.rejected]: (Settelments, action) => {
      Settelments.status = "failed";
      Settelments.error = action.error.message;
    },
    ///////////For ClientList Dropdown ///////////////
    [SettelmentReportsData.pending]: (Settelments, action) => {
      Settelments.status = "pending";
    },
    [SettelmentReportsData.fulfilled]: (Settelments, action) => {
      Settelments.reportData = action.payload;
    },
    [SettelmentReportsData.rejected]: (Settelments, action) => {
      Settelments.status = "failed";
      Settelments.error = action.error.message;
    },
  },
});

export const { SettelmentTableview } = SettelmentsSlice.actions;
// Exporting slice reducer
export default SettelmentsSlice.reducer;

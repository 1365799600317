import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";
import { generateUniqueId } from "../utilities/generateUniqueId";
import listDateToString from "../utilities/listDateToString";

const formsResetToInitialState = {
  status: "idle",
  error: "",
  status: "",
};

export const updateResponseApi = createAsyncThunk(
  "updateResponseApi/Single Update",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/saveDebitTxn`,
      requestParam
    );

    return response.data;
  }
);
export const BulkupdateResponseApi = createAsyncThunk(
  "updateResponseApi/Bulk Upload",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/updateSponsorSettlement`,
      requestParam
    );

    return response.data;
  }
);

// viewSelected Slice----

const updateResponseApiSlice = createSlice({
  name: "updateResponseApi",
  initialState: formsResetToInitialState,
  reducers: {},
  extraReducers: {
    ///Reducers to Generate link for Retrying Payment ---Merchant on Click Generate Link
    [updateResponseApi.pending]: (state, action) => {
      state.status = "pending";
    },
    [updateResponseApi.fulfilled]: (state, action) => {},
    [updateResponseApi.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [BulkupdateResponseApi.pending]: (state, action) => {
      state.status = "pending";
    },
    [BulkupdateResponseApi.fulfilled]: (state, action) => {
      state.status = "fulfilled";
    },
    [BulkupdateResponseApi.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

// Exporting slice reducer
export default updateResponseApiSlice.reducer;

export const ValidationForEndDateAndStartDate = (startDate, endDate) => {
  const startDateForCheck = new Date(startDate.value);
  const endDateForCheck = new Date(endDate.value);

  if (startDateForCheck.getTime() > endDateForCheck.getTime()) {
    endDate.setError(true);
    endDate.setHelperText("End Date can't be less than Start Date");
  }
  return {
    toProceed: startDateForCheck.getTime() <= endDateForCheck.getTime(),
    message: "End Date can't be less than Start Date",
  };
};

const prod = {
  environment: "prod",

  bankInvokePath: "/",
  // backendUrl: "http://192.168.34.61:8081/subscription",
  // backendUrl: "https://subscriptionapi.sabpaisa.in/subscription",
  backendUrl: "https://subscription.sabpaisa.in/subscription",

  // proxy: "https://thingproxy.freeboard.io/fetch/",
  proxy: "",
  uploadFileUrl: "https://test-mandate-upload.web.app",
};
export default prod;

import React, { Fragment, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core";

/////////////////////////////////////////////
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { processMandatePayoutRequest } from "../../../store/onlineEmandate";
import { exportToCSV } from "../../../utilities/exportToCSV";

//Redux Actions
// import { useDispatch, } from "react-redux";

const useStyles = makeStyles((theme) => ({
  MaterialTableWrapper: {
    flex: 1,
  },
  
}));

function MaterialTableGrid(props) {
  
  const classes = useStyles();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };



  const actions={ "actions" : 
    // props.actions
    props.forTable
      ? props.forTable === "MandateReport" ||
        props.forTable === "debitRequests"
        ? props.actions &&
          props.actions.map((action) => {
            return (rowData) => ({
              ...action,
              hidden: action.hidden ? true : false,
            });
          })
        : null
      : (props.actions &&
          props.actions.map((action) => {
            return (rowData) => ({
              ...action,
              position: "row",

              hidden: action.updateCancelMandateStatus
                ? action.currentUserFilterId === 0
                  ? !rowData.is_approved
                  : true
                : action.approveCancelMandate
                ? rowData.is_approved
                : rowData.paymentStatus === "SUCCESS" &&
                  props.debitTansactionReport,
            });
          })) || [props.bulkActions]
  }


  return (
    <Fragment>
      <div className={classes.MaterialTableWrapper}>
        <MaterialTable
          isLoading={props.isLoading}
          title={props.title ? props.title : ""}
          columns={props.columns}
          icons={tableIcons}
          data={props.tableData}
          // data={modifiedData}
          // data={props.transactionDetails}
          onRowClick={props.onRowClick}
          // actions={[props.bulkActions]}
          {...actions}
          
          options={{
            pageSize: 5,
            selection: props.selection ? props.selection : false,
            //to keep update button at the end
            actionsColumnIndex: -1,

            ////
            paging: true,
            exportButton: {
              csv: true,
            },
            exportCsv: () => {
              exportToCSV(props.tableData, props.title);
            },
            doubleHorizontalScroll: true,

            // tableLayout: "fixed",
            headerStyle: {
              "&:hover": {
                backgroundColor: "green !important",
              },
              position: "sticky",
              top: 0,
              backgroundColor: "rgb(1, 87, 155)",
              color: "#FFF",
            },

            // rowStyle: (rowData) => ({
            //   backgroundColor:
            //     selectedRowId === rowData.tableData.id ? "#BBB" : "#FFF",
            // }),
          }}
          onSelectionChange={props.onRowSelect && props.onRowSelect}
          localization={{
            body: {
              emptyDataSourceMessage:
                "I can't find the result for you with the given search, I'm sorry, could you try it once again.",
            },
            header : {
              actions: ''
            }
          }}
        />
      </div>
    </Fragment>
  );
}
export default withRouter(MaterialTableGrid);

// Not in use as of now --------3rd March 2021
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

const adHocView = {
  isAdhocMandateTabActive:false
};

export const fetchRandomApi = createAsyncThunk(
  "selectedView/fetchRandomApi",
  async () => {
    const response = await Axios.get(
      "https://jsonplaceholder.typicode.com/posts"
    );
    return response.data;
  }
);

// viewSelected Slice----

const adHocViewSlice = createSlice({
  name: "adHocView",
  initialState: adHocView,
  reducers: {
    adhocMandateTabControl: (adHocView, action) => {
      adHocView.isAdhocMandateTabActive = action.payload.isAdhoc;
    },
  },
  extraReducers: {},
});

// Exporting uiState actions
export const {adhocMandateTabControl} = adHocViewSlice.actions;

// Exporting slice reducer
export default adHocViewSlice.reducer;

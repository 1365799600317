import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";

const cancelEmandate = {
  cancelMandateForm: {
    p_umr_number: "",
    p_reason_of_cancellation: "",
    p_consumer_name: "",
    p_consumer_mobile: "",
    p_consumer_email: "",
    p_consumer_reference_number: "",
    p_scheme_reference_number: "",
    p_request_type: "Mandate Cancellation",
    p_merchant_code: "LPSD1",
  },
  mandateDetailsForCancellation: {
    mandateRegistrationId: "",
    clientCode: "",
    clientRegistrationId: "",
    consumerReferenceNumber: "",
    mandatePurpose: "",
    payerUtilitityCode: "",
    payerName: "",
    mandateEndDate: null,
    mandateMaxAmount: "",
    mandateRegTime: [],
    mandateType: "",
    merchantId: "",
    mandateStartDate: [],
    messageId: "",
    mandateCollectAmount: null,
    panNo: "",
    mandateCategoryCode: "",
    npciPaymentBankCode: "",
    payerAccountNumber: "",
    payerAccountType: "",
    payerBank: "",
    payerEmail: "",
    payerMobile: "",
    telePhone: "",
    payerBankIfscCode: "",
    authenticationMode: "",
    frequency: "",
    requestType: "",
    schemeReferenceNumber: "",
    sponserBank: "",
    regestrationStatus: "",
    totalAmount: null,
    umrnNumber: "",
    untilCancelled: true,
    mandateCreditTime: [],
    mandateupdatedon: null,
    mandateupdatedby: null,
    regestrationErrorCode: null,
    regestrationErrorDesc: null,
    regestrationNpciRefId: "",
    accptDetails_CreDtTm: "",
    bankName: "",
    mandatecategory: null,
    mandatPhysicalPath: null,
    isphymndtupdate: false,
    isphymndtupdatedon: null,
    isphymndtupdatedby: null,
    isphymndtapprove: false,
    isphymndtapproveon: null,
    isphymndtapproveby: null,
    userType: "",
    mandateImage: null,
    emiamount: "",
    mandateCancelled: false,
    reqInitPty: null,
    accptd: "",
    reasonCode: "",
    reasonDesc: "",
    rejectBy: "",
    accptRefNo: "",
  },
  status: "",
  error: "",
};

// Final Step to submit Cancel mandate form
export const cancelMandateFormSubmitted = createAsyncThunk(
  "cancelEmandate/cancelMandateFormSubmitted",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/registrationCancellation`,
      requestParam
    );
    return response.data;
  }
);
export const getMandateByUmrn = createAsyncThunk(
  "cancelEmandate/getMandateByUmrn",
  async (requestParam) => {
    const data = new FormData();
    data.append("umrn", requestParam);
    const response = await Axios.get(
      `${config.backendUrl}/npci/getMandateByUmrn`,
      { params: { umrn: requestParam } }
    );
    // .then((res) => return res.data)
    // .catch((err) => err.data);
    return response.data;
  }
);

const cancelEmandateSlice = createSlice({
  name: "cancelEmandate",
  initialState: cancelEmandate,
  reducers: {},

  extraReducers: {
    [cancelMandateFormSubmitted.pending]: (state, action) => {
      state.status = "pending";
    },
    [cancelMandateFormSubmitted.fulfilled]: (state, action) => {},
    [cancelMandateFormSubmitted.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    /////////////////////////////////////////////////////////////////////
    [getMandateByUmrn.pending]: (state, action) => {
      state.status = "pending";
    },
    [getMandateByUmrn.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.mandateDetailsForCancellation = action.payload;
    },
    [getMandateByUmrn.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

// Exporting onlineEmandate actions
export const {} = cancelEmandateSlice.actions;

// Exporting slice reducer
export default cancelEmandateSlice.reducer;

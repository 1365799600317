import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../helper/Formik/FormControl/FormControl";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { viewSelected } from "../../store/selectedView";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTransactionsEnquiry,
  TransactionsEnquiryData,
} from "../../store/TransactionsEnquiry";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: "fit-content",
    minWidth: "82vw",
    maxWidth: "82vw",
  },
  buttonStyle: {
    padding: "20px",
  },
}));

const columns = [
  { title: "Payee Name", field: "payeeName" },
  { title: "ClientID", field: "clientId" },
  { title: "Txn Complete Date", field: "transCompleteDate" },
  { title: "Emi Amount", field: "emiAmount" },
  { title: "Payment Mode", field: "paymentMode" },
  { title: "Client Name", field: "clientName" },
  { title: "Client Code", field: "clientCode" },
  { title: "Tnx Date", field: "transDate" },
  { title: "Payee Mobile", field: "payeeMobile" },
  { title: "Payee Mail", field: "payeeMail" },
  { title: "Txn ID", field: "txnId" },
  { title: "Status", field: "status" },
  { title: "Message", field: "message" },
];

function TransactionEnquiry(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("TransactionEnquiry");
  const listItem = useSelector(
    (state) => state.entities.TransactionsEnquirySlice.TransactionsEnquiryData
  );
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const initialValues = {
    TransactionId: "",
  };
  const validationSchema = Yup.object({});
  const onSubmit = (values) => {
    setLoading(true);
    dispatch(TransactionsEnquiryData(values.TransactionId))
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    //   effect
    return () => {
      dispatch(clearTransactionsEnquiry());
    };
  }, []);
  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static">
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Transaction Enquiry" value="TransactionEnquiry" />
          </TabList>
        </AppBar>
        <TabPanel value="TransactionEnquiry">
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form>
                    <Grid container>
                      <Paper></Paper>
                      <Grid item md={4} xs={12}>
                        <FormikControl
                          control="input"
                          label="Transaction ID"
                          name="TransactionId"
                          onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^[0-9]*$/;
                            if (!value || regex.test(value.toString())) {
                              formik.setFieldValue("TransactionId", value);
                            }
                          }}
                          type="tel"
                          min="0"
                          maxlength="18"
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        justifyContent="center"
                        className={classes.buttonStyle}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Search{" "}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                {" "}
                <List>
                  <ListItem className={classes.listHeader}>
                    <ListItemText primary=" " />
                  </ListItem>
                </List>
                <List>
                  <ListItem className={classes.nested}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      item={true}
                    >
                      {columns.map((item, index) => {
                        return (
                          <Grid item xs={12} sm={4} key={index}>
                            {console.log(listItem[`${item.field}`])}
                            {listItem[`${item.field}`] === "" ||
                            listItem[`${item.field}`] === undefined ||
                            listItem[`${item.field}`] === "not found" ? (
                              <></>
                            ) : (
                              <ListItemText
                                primary={item.title}
                                secondary={listItem[`${item.field}`]}
                              />
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </ListItem>
                </List>
              </>
            )}
          </>
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default TransactionEnquiry;

import {
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import MaterialTableGrid from "../../Reports/components/MaterialTableGrid";
import FilterForSettelmentsReport from "./FilterForSettelmentsReport";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "50px",
  },
  NoResultsView: {
    textAlign: "center",
    padding: "50px",
  },
}));

export default function SettelmentsReports(props) {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const report_data = useSelector(
    (state) => state.entities.Settelments.reportData
  );
  const currentUser = useSelector((state) => state.auth.currentUser);
  const showTheTableOrNot = useSelector(
    (state) => state.entities.Settelments.SettelmentTableview
  );
  const tableData = report_data ? report_data : [];

  const columns = [
    { title: "id", field: "id" },
    { title: "TXn ID", field: "trans_id" },
    { title: "Merchant Code", field: "merchant_code" },
    { title: "Merchant Name", field: "merchant_name" },
    { title: "Settelment Amt", field: "settlement_amount" },
    { title: "Settelment date", field: "settlement_date" },
    { title: "PayerName", field: "payer_name" },
    { title: "Payer Mob", field: "payer_mobile" },
    { title: "Payer Email", field: "payer_email" },
    { title: "Payer bank", field: "payer_bank" },
    { title: "Payer Acc No", field: "payer_account_number" },
    { title: " Payer Bank IFSC Code", field: "payer_bank_ifsc_code" },
  ];

  //   const [openViewDebitTransaction, setOpenViewDebitTransaction] =
  //     React.useState(false);

  //   const handleCloseViewDebitTransaction = () => {
  //     setOpenViewDebitTransaction(false);
  //   };
  const [rowData, setRowData] = useState("");
  const viewMandateDetails = {
    icon: () => (
      <Button color="primary" variant="contained">
        View
      </Button>
    ),
    tooltip: "View Physical Mandate",
    onClick: (e, rowData) => {
      setRowData(rowData);
      //   setOpenViewDebitTransaction(true);
    },
  };
  return (
    <Fragment>
      {/* ---------------------------------------- */}

      {/* {rowData && (
        <DialogWrapper
          title="Debit Transaction"
          open={openViewDebitTransaction}
          handleClose={handleCloseViewDebitTransaction}
        >
          <DebitTransactionContent listItem={rowData} />
        </DialogWrapper>
      )} */}

      {/* ------------------------------- */}
      <Grid container spacing={2} direction="column" item xs={12}>
        {/* ----------Hidden Filter Menu Here----------- */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <FilterForSettelmentsReport />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          {tableData.length === 0 ? (
            <Paper className={classes.NoResultsView}>
              {!showTheTableOrNot ? <LinearProgress /> : "I am sorry, I cannot find the result for the given search query. Could you please try once again?"}
            </Paper>
          ) : (
            <MaterialTableGrid
              // actions={[viewMandateDetails]}
              debitTansactionReport={true}
              isLoading={!showTheTableOrNot}
              title="SETTELMENTS REPORTS TABLE"
              tableData={JSON.parse(JSON.stringify(tableData))}
              columns={columns}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-browser-router";
import queryString from "query-string";

import { Dialog, DialogContent } from "@material-ui/core";
import MandateRegistrationFailed from "./components/MandateRegistrationFailed";
import MandateRegistrationSuccess from "./components/MadateRegistrationSuccess";
import { useDispatch, useSelector } from "react-redux";
import { viewSelected } from "../../../../store/selectedView";
import Axios from "axios";
import config from "../../../../config";
import { settingBackDropWithProgress } from "../../../../store/uiState";
import RegisteredPhysicalMandateView from "../../../emandateOffline/components/RegisteredPhysicalMandate/RegisteredPhysicalMandateView";
import {
  sendGeneratedLinkOrMessageThroughEmail,
  sendGeneratedLinkOrMessageThroughMessage,
} from "../../../../store/onlineEmandate";
import GetmessageForMandateRegistration from "../../../../utilities/GetmessageForMandateRegistration";

function MandateRegistrationStatusView(props) {
  const dispatch = useDispatch();
  const [apiData, setApiData] = useState("");

  const responseFromBackend = queryString.parse(props.location.search);
  const backDropWithProgress = useSelector(
    (state) => state.uiState.backDropWithProgress
  );

  useEffect(() => {
    dispatch(settingBackDropWithProgress({ backDropWithProgress: true }));
    Axios.get(
      `${config.backendUrl}/npci/registration/status/${responseFromBackend.mendateRegId}`
    )
      .then((res) => {
        setApiData(res.data);
        dispatch(settingBackDropWithProgress({ backDropWithProgress: false }));
        dispatch(
          sendGeneratedLinkOrMessageThroughEmail({
            toEmail: res.data.payerEmail,
            toCc: "",
            subject: "MANDATE REGISTRATION STATUS",

            msg: GetmessageForMandateRegistration(
              res.data.regestrationStatus.toLowerCase(),
              {
                mandateRegistrationId: res.data.mandateRegistrationId,
                clientRegistrationId: res.data.clientRegistrationId,
                regestrationStatus: res.data.regestrationStatus,
                reasonDesc: res.data.reasonDesc,
                consumerReferenceNumber: res.data.consumerReferenceNumber,
                mandateType: res.data.mandateType,
              }
            ),
          })
        );
        if (
          res.data.mandateType === "ONLINE" &&
          res.data.regestrationStatus === "SUCCESS"
        ) {
          dispatch(
            sendGeneratedLinkOrMessageThroughEmail({
              toEmail: "sonu.kumar@srslive.in",
              toCc: "rahmat.ali@sabpaisa.in,nadeem.jairajpuri@sabpaisa.in",
              subject: "MANDATE REGISTRATION",

              msg: `Dear SabPaisa, one e-mandate with mandate Id ${res.data.mandateRegistrationId} Created.`,
            })
          );
          dispatch(
            sendGeneratedLinkOrMessageThroughMessage({
              mobilenumber: 9386737434,
              message: "Dear SabPaisa, one e-mandate created.",
            })
          );
        }
        dispatch(
          sendGeneratedLinkOrMessageThroughMessage({
            mobilenumber: res.data.payerMobile.split("-")[1],
            message: GetmessageForMandateRegistration(
              res.data.regestrationStatus.toLowerCase(),
              {
                mandateRegistrationId: res.data.mandateRegistrationId,
                clientRegistrationId: res.data.clientRegistrationId,
                regestrationStatus: res.data.regestrationStatus,
                reasonDesc: res.data.reasonDesc,
                consumerReferenceNumber: res.data.consumerReferenceNumber,
                mandateType: res.data.mandateType,
              }
            ),
          })
        );
      })
      .catch((error) => {
        dispatch(settingBackDropWithProgress({ backDropWithProgress: false }));
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(viewSelected({ viewSelected: "E-Mandate" }));
    return () => {};
  }, [dispatch]);
  // const classes = useStyles();
  const [toShowPhysicalMandateForm, setToShowPhysicalMandateForm] =
    useState(false);

  return (
    <Fragment>
      {!backDropWithProgress && apiData !== "" ? (
        <Dialog open={true}>
          <DialogContent>
            <Fragment>
              {apiData.mandateType.toLowerCase() === "ONLINE".toLowerCase() && (
                <div>
                  {apiData.regestrationStatus.toLowerCase() ===
                  "Success".toLowerCase() ? (
                    <MandateRegistrationSuccess apiData={apiData} />
                  ) : (
                    <MandateRegistrationFailed apiData={apiData} />
                  )}
                </div>
              )}

              {apiData.mandateType.toLowerCase() ===
                "PHYSICAL".toLowerCase() && (
                <div>
                  {/*---------- Physical Mandate Component goes in here---------- */}
                  {apiData.regestrationStatus.toLowerCase() ===
                  "INITIATED".toLowerCase() ? (
                    <Fragment>
                      <MandateRegistrationSuccess
                        apiData={apiData}
                        successFor="physical"
                        setToShowPhysicalMandateForm={
                          setToShowPhysicalMandateForm
                        }
                        toShowPhysicalForm={toShowPhysicalMandateForm}
                      />
                      <RegisteredPhysicalMandateView
                        apiData={apiData}
                        toShowPhysicalMandateForm={toShowPhysicalMandateForm}
                        setToShowPhysicalMandateForm={
                          setToShowPhysicalMandateForm
                        }
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <MandateRegistrationFailed apiData={apiData} />
                    </Fragment>
                  )}
                </div>
              )}
            </Fragment>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </Fragment>
  );
}
export default withRouter(MandateRegistrationStatusView);

import { makeStyles, TextField } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  display: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    border: "none",
  },
}));
export default function DisabledTextField(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.display}>
      {props.label}
      <TextField
        classNAme={classes.textField}
        disabled={true}
        id="filled-size-small"
        value={props.labelValue}
        variant="standard"
        InputProps={{ disableUnderline: true }}
        size="medium"
      />
    </div>
  );
}

import AllRoutes from "./routes/AllRoutes";
// import { useEffect } from "react";
// import {  useSelector } from "react-redux";
// import { setCurrentUserFromCookies } from "./store/auth";
// import { isObjectEmpty } from "./utilities/isObjectEmpty";
// import { getCookies } from "./utilities/getCookies";

import { withRouter } from "react-browser-router";

import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + theme.zIndex.appBar + 1,
    color: "#fff",
  },
}));

// ----------------------------------This can Control copy and paste functionality on application----------------------------------
// document.addEventListener("contextmenu", function(evt){
//   evt.preventDefault();
// }, false);
//---------------------------------------------------------------------------------------------------------------------------------

function App(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  //-------------------------------------------This code will be used when we have JWT implemented--------------------------------

  // const dispatch = useDispatch();
  // const currentUser = useSelector((state) => state.auth.currentUser);

  // useEffect(() => {
  //   if (isObjectEmpty(currentUser)) {
  //     if (getCookies().currentUser != undefined) {
  //       dispatch(
  //         setCurrentUserFromCookies({
  //           currentUser: JSON.parse(getCookies().currentUser),
  //         })
  //       );
  //     } else {
  //       props.history.push("/login");
  //     }
  //   } else {
  //     props.history.push("/login");
  //   }
  //   return () => {};
  // }, []);

  //-----------------------------------------------------------------------------------------------------------------------------------------

  const backDropWithProgress = useSelector(
    (state) => state.uiState.backDropWithProgress
  );

  return (
    <div className="App">
      {/* ------------------------------------This is the BackDrop to show in case of an async fetch --------------------------------------*/}
      <Backdrop className={classes.backdrop} open={backDropWithProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* ------------------------------------------------------------------------------------------ --------------------------------------*/}

      {/* ---------------------------------------This has all views with routes connected to it -------------------------------------------*/}

      <AllRoutes />

      {/* ------------------------------------------------------------------------------------------ --------------------------------------*/}
    </div>
  );
}

export default withRouter(App);

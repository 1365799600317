import React, { useState, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { ButtonBase, CircularProgress, Snackbar, Tooltip } from "@material-ui/core";
import ShareIcon from '@material-ui/icons/Share';


import ShareButtonFunctionality from "../../view/emandateOnline/components/PayoutRequest/components/Forms/PersonalDetailsForm/ShareButtonFunctionality"
import SharelinkButton from "../ReusableComponents/ShareLinkButton"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function GenerateLinkDialog(props) {
  const [openSnactbar, setOpenSnackBar] = useState(false);
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSnactbar}
        onClose={() => setOpenSnackBar(!openSnactbar)}
        message="Copied Mandate Link"
        key={"bottom" + "right"}
        transitionDuration={700}
        resumeHideDuration={3000}
      />

      <Dialog
        maxWidth="lg"
        onClose={() =>
          props.setOpenGeneratedLinkDialog(
            !props.openGeneratedLinkDialog
          )
        }
        aria-labelledby="customized-dialog-title"
        open={props.openGeneratedLinkDialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() =>
            props.setOpenGeneratedLinkDialog(
              !props.openGeneratedLinkDialog
            )
          }
        >
          {props.dialogTitle}
        </DialogTitle>

        <DialogContent dividers>
          {!props.generatedLink && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {props.generatedLink && (
            <Fragment>
              <Typography gutterBottom>
                {props.dialogContent}
              </Typography>
                   <br/>
              <Typography gutterBottom style={{
                  textOverflow: "ellipsis",
                  overflow:"hidden",
                  whiteSpace:'nowrap',
                  maxWidth:'900px'
                  }}>
              <Tooltip title="Copy to ClipBoard">
                <ButtonBase
                  onClick={(e) => {
                    navigator.clipboard.writeText(props.generatedLink);
                    setOpenSnackBar(true);
                  }}
                >
                  <FileCopyOutlinedIcon style={{ marginRight: "10px" }} />
                </ButtonBase>
                </Tooltip>
                {props.generatedLink}
              </Typography>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
        {props.disableShare?
        <></>
        :
        <SharelinkButton 
          generatedLink={props.generatedLink} 
          userDetails={props.userDetails}
          msg={props.msg} 
          msgSubject={props.msgSubject}
          useCustomActions={props.useCustomActions}
      />
        }
        </DialogActions>
      </Dialog>
    </div>
  );
}

//This slice consist of slice for state management in the application

import { combineReducers } from "redux";
import selectedViewReducer from "./selectedView";
import onlineEmandateReducer from "./onlineEmandate";
import emandateListFilterReducer from "./emandateList";
import userManagementReducer from "./userManagement";
import merchantManagementReducer from "./merchantManagement";
import reportsDataReducer from "./reportsData";
import activitiesReducer from "./activitiesView";
import adHocView from "./adHocView";
import mandatePayment from "./mandatePayment";
import cancelEmandateReducer from "./cancelEmandate";
import bulkOptionsReducer from "./bulkOptions";
import penaltyTokenReducer from "./penaltyTokenStore";
import updateResponseApiSlice from "./updateResponse";
import Settelments from "./Settelments";
import downloadPhysicalMandateSlice from "./downloadPhysicalMandate";
import debitRequestSlice from "./debitRequest";
import invoivceSlice from "./invoivce";
import MisReportSlice from "./MisReport";
import TransactionsEnquirySlice from "./TransactionsEnquiry";
import mandateEnquirySlice from "./mandateEnquiry"
export default combineReducers({
  //This is for Current Selected View in the application Flow--
  selectedView: selectedViewReducer,
  onlineEmandate: onlineEmandateReducer,
  emandateFilterList: emandateListFilterReducer,
  userManagement: userManagementReducer,
  merchantManagement: merchantManagementReducer,
  reportsData: reportsDataReducer,
  activities: activitiesReducer,
  adHocView: adHocView,
  mandatePayment: mandatePayment,
  cancelEmandateReducer: cancelEmandateReducer,
  bulkOptions: bulkOptionsReducer,
  penaltyToken: penaltyTokenReducer,
  updateResponseApiSlice: updateResponseApiSlice,
  Settelments: Settelments,
  downloadPhysicalMandateSlice: downloadPhysicalMandateSlice,
  debitRequestSlice: debitRequestSlice,
  invoivceSlice: invoivceSlice,
  MisReportSlice: MisReportSlice,
  TransactionsEnquirySlice: TransactionsEnquirySlice,
  mandateEnquirySlice:mandateEnquirySlice
});

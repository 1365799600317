import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../../TextError/TextError";
import "./Input-Checkbox.css";
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

function Input_Checkbox(props) {
  const { label, name, name1, name2, options, ...rest } = props;
  const [untilChecked, setUntilChecked] = React.useState(false);
  return (
    <div className="form__control">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "25px",
        }}
      >
        <label htmlFor={name}>{label}</label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Field name={name1} id={name1} {...rest}>
            {({ field, form }) => {
              const { setFieldValue } = form;
              setUntilChecked(field.value[0]);
              return options.map((option) => {
                return (
                  <React.Fragment key={option.key}>
                    <input
                      type="checkbox"
                      id={name1}
                      {...field}
                      value={option.value}
                      checked={field.value.includes(option.value)}
                      onChange={(e) => {
                        // setFieldValue(name1,e.target.value)
                        if (field.value.length === 0) {
                          setFieldValue(name1, [true]);
                        } else {
                          setFieldValue(name1, []);
                        }
                        // setFieldValue(name1, ['true'])

                        setFieldValue(name2, null);
                      }}
                    />
                    <p htmlFor={option.value}>{option.key}</p>
                  </React.Fragment>
                );
              });
            }}
          </Field>
        </div>
      </div>
      {/* <div className="form__control__field__div"> */}
      <Field name={name2}>
        {({ field, form }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            // <DateView
            //   className="input__field"
            //   id={name2}
            //   {...field}
            //   {...rest}
            //   selected={value}
            //   onChange={(val) => setFieldValue(name2, val)}
            //   value={value}
            //   disabled={untilChecked}
            // />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="input__field"
                id={name2}
                {...field}
                {...rest}
                selected={value}
                style={{ display: "flex" }}
                onChange={(val) => setFieldValue(name2, val)}
                value={value}
                disabled={untilChecked}
                format="MM/dd/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          );
        }}
      </Field>
      {/* </div> */}
      {/* <ErrorMessage name={name1} component={TextError} /> */}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default Input_Checkbox;

export default function GetmessageForMandateRegistration(Status, data={}) {
  if (Status === "failed") {
    
    let mandateType= data.mandateType==="PHYSICAL"?"PHYSICAL":"ONLINE"
    return `Your Process Was A Faliure
        Mandate Details\r\n
      ${
        data.mandateRegistrationId
          ? ` Mandate Registration id : ${data.mandateRegistrationId}`
          : ``
      }\r\n
      ${
        data.clientRegistrationId
          ? ` Client Registration id : ${data.clientRegistrationId}`
          : ``
      }\r\n
      ${
        data.regestrationStatus ? ` Registration Status : ${data.regestrationStatus}` : ``
      }\r\n
      ${
        data.reasonDesc
          ? `  Your ${mandateType}-Mandate registration is rejected due to : ${data.reasonDesc}`
          : ``
      }\r\n
        
        `;
  }
  if (Status === "initiated") {
    let mandateType= data.mandateType==="PHYSICAL"?"PHYSICAL":"ONLINE"
    return `Your Process Was INITIATED
        Mandate Details\r\n
      ${
        data.mandateRegistrationId
          ? ` Mandate Registration id : ${data.mandateRegistrationId}`
          : ``
      }\r\n
      ${
        data.clientRegistrationId
          ? ` Client Registration id : ${data.clientRegistrationId}`
          : ``
      }\r\n
      ${
        data.regestrationStatus ? ` Registration Status : ${data.regestrationStatus}` : ``
      }\r\n
      ${
        data.consumerReferenceNumber
          ? `  Your Consumer Reference Number for This ${mandateType}-Mandate registration is: ${data.consumerReferenceNumber}`
          : ``
      }\r\n
        
        `;
  }
  if (Status === "success") {
    let mandateType= data.mandateType==="PHYSICAL"?"PHYSICAL":"ONLINE"

    return `Your Process Was A Success
        Mandate Details\r\n
      ${
        data.mandateRegistrationId
          ? ` Mandate Registration id : ${data.mandateRegistrationId}`
          : ``
      }\r\n
      ${
        data.clientRegistrationId
          ? ` Client Registration id : ${data.clientRegistrationId}`
          : ``
      }\r\n
      ${
        data.regestrationStatus ? ` Registration Status : ${data.regestrationStatus}` : ``
      }\r\n
      ${
        data.reasonDesc
          ? `  Your ${mandateType}-Mandate registration is rejected due to : ${data.reasonDesc}`
          : ``
      }\r\n
        
        `;
  }
  return`default`
}

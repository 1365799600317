import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-browser-router";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import HomeIcon from "@material-ui/icons/Home";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LayersIcon from "@material-ui/icons/Layers";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import Collapse from "@material-ui/core/Collapse";
// import StarBorder from "@material-ui/icons/StarBorder";
import PublishIcon from "@material-ui/icons/Publish";
import CancelIcon from "@material-ui/icons/Cancel";
import UpdateIcon from "@material-ui/icons/Update";
import {
  menuIconForSmallScreenSideDrawerOpenClicked,
  viewSelected,
} from "../../../../store/selectedView";
import { useDispatch, useSelector } from "react-redux";
import { generateUniqueId } from "../../../../utilities/generateUniqueId";
import FeaturedPlayListRoundedIcon from "@material-ui/icons/FeaturedPlayListRounded";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import LocalAtmRoundedIcon from "@material-ui/icons/LocalAtmRounded";
import { weekdaysMin } from "moment";
import { getCookies } from "../../../../utilities/getCookies";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  // drawerItems:{
  //   marginBottom:'5px'
  // },

  nested: {
    paddingLeft: theme.spacing(4),
  },

  hide: {
    display: 'none !important'
  }
}));
const drawerWidth = 200;
function SideDrawerItems(props) {
  const [open, setOpen] = useState(false);
  const [cookiePwd, setCookiePwd] = useState("");
  const [openReportCollapsable, setOpenReportsCollapsable] = useState(false);
  const [openAdminCollapsable, setOpenAdminCollapsable] = useState(false);

  const handleClick = (event) => {
    handleListItemClick(event, "Activities");
    setOpen(!open);
  };
  const handleClickForReports = (event) => {
    handleListItemClick(event, "Reports");
    setOpenReportsCollapsable(!openReportCollapsable);
  };
  const handleClickForAdmin = (event) => {
    handleListItemClick(event, "Admin");
    setOpenAdminCollapsable(!openAdminCollapsable);
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const currentView = useSelector(
    (state) => state.entities.selectedView.currentView
  );

  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {

    if (typeof (getCookies().currentUser) !== "undefined") {
      const cookies = JSON.parse(getCookies().currentUser);
      const password = cookies?.password;
      setCookiePwd(password)
    }
  }, [])


  // 



  const handleListItemClick = (event, sideDrawerItemName) => {
    dispatch(viewSelected({ viewSelected: sideDrawerItemName }));

    if (sideDrawerItemName === "Home") {
      props.history.push("/home");
    }
    if (sideDrawerItemName === "Create Bulk Mandate") {
      props.history.push("/create-bulk-mandate");
    }
    if (sideDrawerItemName === "Create Mandate") {
      if (props.location.pathname.indexOf("/mandateRegistration") < 0) {
        props.history.push(`/mandateRegistration/${generateUniqueId()}`);
      }
    }
    if (sideDrawerItemName === "Cancel Mandate") {
      props.history.push("/cancelMandate");
    }
    if (sideDrawerItemName === "Upload File") {
      props.history.push("/uploadFile");
    }
    if (sideDrawerItemName === "Update Response") {
      props.history.push("/updateResponse");
    }
    if (sideDrawerItemName === "Users") {
      props.history.push("/userManagement");
    }
    if (sideDrawerItemName === "Merchants") {
      props.history.push("/merchantManagement");
    }
    // if (sideDrawerItemName === "Reports") {
    //   props.history.push("/reports");
    // }
    if (sideDrawerItemName === "Mandate Reports") {
      props.history.push("/reports/mandateReports");
    }
    if (sideDrawerItemName === "Pending Mandate Reports") {
      props.history.push("/reports/pendingMandateReports");
    }
    if (sideDrawerItemName === "Debit Reports") {
      props.history.push("/reports/debitReports");
    }
    if (sideDrawerItemName === "Cancel Mandate Reports") {
      props.history.push("/reports/cancelMandateReports");
    }
    if (sideDrawerItemName === "Bulk Operations") {
      props.history.push("/bulkOperations");
    }
    if (sideDrawerItemName === "Settelments") {
      props.history.push("/settelments");
    }
    if (sideDrawerItemName === "Debit Requests") {
      props.history.push("/DebitRequests");
    }
    if (sideDrawerItemName === "Payment Detail Summary") {
      props.history.push("/PaymentDetailSummary");
    }
    if (sideDrawerItemName === "MIS Reports") {
      props.history.push("/MisReport");
    }
    if (sideDrawerItemName === "Settelments Reports") {
      props.history.push("/reports/SettelmentsReports");
    }
    if (sideDrawerItemName === "Transaction Enquiry") {
      props.history.push("/TransactionEnquiry");
    }
    if (sideDrawerItemName === "Mandate Enquiry") {
      props.history.push("/MandateEnquiry");
    }
    if (sideDrawerItemName === "Registered Physical Mandate") {
      props.history.push("/registeredPhysicalMandate");
    }


    //     const RegisteredPhysicalMandateView = lazy(() =>
    //   import(
    //     "../view/emandateOffline/components/RegisteredPhysicalMandate/RegisteredPhysicalMandateView"
    //   )
    // );

  };
  return (
    <List>
      <Fragment>
        <ListItem
          button
          selected={currentView === "Home"}
          onClick={(event) => handleListItemClick(event, "Home")}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {/* <Divider /> */}

        {cookiePwd !== "direct" && (
          <>
            <ListItem
              button
              selected={currentView === "Create Mandate"}
              onClick={(event) => handleListItemClick(event, "Create Mandate")}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Create Mandate" />
            </ListItem>

            <ListItem
              button
              selected={currentView === "Create Bulk Mandate"}
              onClick={(event) => handleListItemClick(event, "Create Bulk Mandate")}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Create Bulk Mandate" />
            </ListItem>
          </>
        )}

        {/* <ListItem
          button
          selected={currentView === "Bulk Operations"}
          onClick={(event) => handleListItemClick(event, "Bulk Operations")}
          className={classes.hide}
        >
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Bulk Operations" />
        </ListItem> */}

        <ListItem
          button
          selected={currentView === "Cancel Mandate"}
          onClick={(event) => handleListItemClick(event, "Cancel Mandate")}
        >
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          <ListItemText primary="Cancel Mandate" />
        </ListItem>

        {/* /////////////////////////////////////////// */}
        {currentUser.filter_id === 0 ? (
          <>
            <ListItem
              button
              selected={currentView === "Activities"}
              onClick={(e) => handleClick(e)}
            >
              <ListItemIcon>
                <LocalActivityIcon />
              </ListItemIcon>
              <ListItemText primary="Activities" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  selected={currentView === "Upload File"}
                  onClick={(event) => handleListItemClick(event, "Upload File")}
                >
                  <ListItemIcon>
                    <PublishIcon />
                  </ListItemIcon>
                  <ListItemText primary="Upload File" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  selected={currentView === "Update Response"}
                  onClick={(event) =>
                    handleListItemClick(event, "Update Response")
                  }
                >
                  <ListItemIcon>
                    <UpdateIcon />
                  </ListItemIcon>
                  <ListItemText primary="Update Response" />
                </ListItem>

              </List>
            </Collapse>
          </>
        ) : (
          <></>
        )}
        {/* ///////////////////////////////////// */}
        <ListItem
          button
          selected={currentView === "Reports"}
          onClick={(e) => handleClickForReports(e)}
        >
          <ListItemIcon>
            <LocalActivityIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
          {openReportCollapsable ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {/* <Divider /> */}

        <Collapse in={openReportCollapsable} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              selected={currentView === "Mandate Reports"}
              onClick={(event) => handleListItemClick(event, "Mandate Reports")}
            >
              <ListItemIcon>
                <FeaturedPlayListRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Registered Mandate Reports" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              selected={currentView === "Debit Reports"}
              onClick={(event) => handleListItemClick(event, "Debit Reports")}
            >
              <ListItemIcon>
                <LocalAtmRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Debit Reports" />
            </ListItem>

            {currentUser.filter_id === 0 ? (
              <>
                <ListItem
                  button
                  className={classes.nested}
                  selected={currentView === "Payment Detail Summary"}
                  onClick={(event) =>
                    handleListItemClick(event, "Payment Detail Summary")
                  }
                >
                  <ListItemIcon>
                    <FeaturedPlayListRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Payment Detail Summary" />
                </ListItem>
              </>
            ) : (
              <></>
            )}
            {currentUser.filter_id === 0 ? (
              <>
                <ListItem
                  button
                  className={classes.nested}
                  selected={currentView === "MIS Reports"}
                  onClick={(event) => handleListItemClick(event, "MIS Reports")}
                >
                  <ListItemIcon>
                    <FeaturedPlayListRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="MIS Reports" />
                </ListItem>
              </>
            ) : (
              <></>
            )}

            <ListItem
              button
              className={classes.nested}
              selected={currentView === "Pending Mandate Reports"}
              onClick={(event) =>
                handleListItemClick(event, "Pending Mandate Reports")
              }
            >
              <ListItemIcon>
                <FeaturedPlayListRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Pending Mandate Reports" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              selected={currentView === "Settelments Reports"}
              onClick={(event) =>
                handleListItemClick(event, "Settelments Reports")
              }
            >
              <ListItemIcon>
                <FeaturedPlayListRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Settlement Reports" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              selected={currentView === "Cancel Mandate Reports"}
              onClick={(event) =>
                handleListItemClick(event, "Cancel Mandate Reports")
              }
            >
              <ListItemIcon>
                <CancelPresentationIcon />
              </ListItemIcon>
              <ListItemText primary="Cancel Mandate Reports" />
            </ListItem>
          </List>
        </Collapse>
        {/* /////////////////////////////////////////// */}
        {currentUser.filter_id === 0 ? (
          <>
            <ListItem
              button
              selected={currentView === "Admin"}
              onClick={(e) => handleClickForAdmin(e)}
            >
              <ListItemIcon>
                <LocalActivityIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
              {openAdminCollapsable ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openAdminCollapsable} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* ----------------------Merchant tab here---------------- */}
                {currentUser.filter_id === 0 && (
                  <ListItem
                    button
                    selected={currentView === "Merchants"}
                    onClick={(event) => handleListItemClick(event, "Merchants")}
                  >
                    <ListItemIcon>
                      <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary="Merchants" />
                  </ListItem>
                )}
                {/* ----------------------Settelments tab here---------------- */}
                {currentUser.filter_id === 0 && (
                  <ListItem
                    button
                    selected={currentView === "Settelments"}
                    onClick={(event) =>
                      handleListItemClick(event, "Settelments")
                    }
                  >
                    <ListItemIcon>
                      <SyncAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settlement" />
                  </ListItem>
                )}
                {/* ----------------------buil operation tab here---------------- */}
                {currentUser.filter_id === 0 && (
                  <ListItem
                    button
                    selected={currentView === "Bulk Operations"}
                    onClick={(event) =>
                      handleListItemClick(event, "Bulk Operations")
                    }
                  >
                    <ListItemIcon>
                      <SyncAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bulk Operations" />
                  </ListItem>
                )}
              </List>

              {/* <ListItem
          button
          selected={currentView === "Bulk Operations"}
          onClick={(event) => handleListItemClick(event, "Bulk Operations")}
          className={classes.hide}
        >
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Bulk Operations" />
        </ListItem> */}
            </Collapse>
          </>
        ) : (
          <></>
        )}
        {/* ------------------TransactionEnquiry tab here------------ */}

        <ListItem
          button
          selected={currentView === "Transaction Enquiry"}
          onClick={(event) => handleListItemClick(event, "Transaction Enquiry")}
        >
          <ListItemIcon>
            <SyncAltIcon />
          </ListItemIcon>
          <ListItemText primary="Transaction Enquiry" />
        </ListItem>
        <ListItem
          button
          selected={currentView === "Mandate Enquiry"}
          onClick={(event) => handleListItemClick(event, "Mandate Enquiry")}
        >
          <ListItemIcon>
            <SyncAltIcon />
          </ListItemIcon>
          <ListItemText primary="Mandate Enquiry" />
        </ListItem>
        {/* ------------------Debit tab here------------ */}

        {currentUser?.merchant_id === 55 || currentUser?.merchant_id === 3 ?
          <ListItem
            button
            selected={currentView === "Debit Requests"}
            onClick={(event) => handleListItemClick(event, "Debit Requests")}
          >
            <ListItemIcon>
              <SyncAltIcon />
            </ListItemIcon>
            <ListItemText primary="Debit Requests" />
          </ListItem> : <></>
        }

        {/* ------------------User tab here------------ */}
        <ListItem
          button
          selected={currentView === "Users"}
          onClick={(event) => handleListItemClick(event, "Users")}
        >
          <ListItemIcon>
            <PersonRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </Fragment>
    </List>
  );
}
export default withRouter(SideDrawerItems);

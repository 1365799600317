import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import ShareIcon from "@material-ui/icons/Share";
import EditIcon from "@material-ui/icons/Edit";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import { useDispatch } from "react-redux";
import {
  sendGeneratedLinkOrMessageThroughMessage,
  sendGeneratedLinkOrMessageThroughEmail,
} from "../../../../../../../store/onlineEmandate";
import customToast from "../../../../../../../utilities/TostNotifications/tostTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 75,
    transform: "translateX(0px)",
    flexGrow: 1,
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function ShareButtonFunctionality(props) {
  const dispatch = useDispatch();
  const handleWhatsAppIconClick = () => {
    // alert("Whatsaap Send");
  };
  const handleEmailOutlinedIconClick = () => {
    dispatch(
      sendGeneratedLinkOrMessageThroughEmail({
        toEmail: props.userDetails.payerEmail,
        toCc: "",
        subject: "Test Subject",
        msg: `Hello Welcome To SabPaisa here is Your  Mandate Link :${props.generatedLink}`,
      })
    ).then((res) => {
      customToast.successToast("Email has been sent");
    });
  };

  const handleMessageOutlinedIconClick = () => {
    dispatch(
      sendGeneratedLinkOrMessageThroughMessage({
        mobilenumber: props.userDetails.payerMobile.split("-")[1],
        message: `Hello Welcome To SabPaisa here is Your Mandate Link :${props.generatedLink} `,
      })
    ).then((res) => {
      customToast.successToast("Message has been sent");
    });
  };

  const actions = [
    // {
    //   icon: <WhatsAppIcon onClick={handleWhatsAppIconClick} />,
    //   name: "Whatsaap",
    // },
    {
      icon: <EmailOutlinedIcon onClick={handleEmailOutlinedIconClick} />,
      name: "email",
    },
    {
      icon: <MessageOutlinedIcon onClick={handleMessageOutlinedIconClick} />,
      name: "Message",
    },
    // { icon: <ShareIcon />, name: "Share" },
  ];

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={classes.speedDial}
        icon={<ShareIcon openIcon={<EditIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={true}
        direction="left"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            placement="bottom"
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
}

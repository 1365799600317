import { Card, CardActionArea, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import SuccessImg from '../../assets/images/success.png'

const useStyles = makeStyles({
    root: {
      maxWidth: 500,
    //   width:400
    },
    media: {
      height: 400,
      backgroundSize:'contain'
    },
  });

function MandateComplete(props) {
    const classes = useStyles();

    return (
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'90vh'}}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                    className={classes.media}
                    image={SuccessImg}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Thank you for registering mandate with us.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                        </Typography>
                    </CardContent>
                </CardActionArea>
            {/* <Typography>Thank you for Registering the Mandate</Typography> */}
            </Card>
            
        </div>
    );
}

export default MandateComplete;
//<-----------------------This module Deals with the Filter system in the Debit Reports Section=--------------------->

////////////////////////////////////////////IMPORTS SECTION////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// <-----------------------------------Material Ui Imports------------------------------------------------------------------>
import { Button, Grid } from "@material-ui/core";
// <-----------------------------------ReusableComponents Imports----------------------------------------------------------->
import DatePickerField from "../../../helper/ReusableComponents/DatePickerField";
import NativeSelectField from "../../../helper/ReusableComponents/NativeSelectField";
// <-----------------------------------Uitility Imports---------------------------------------------------------------------->
import useMuiFormField from "../../../utilities/useMuiFormField";
import { ValidationForEndDateAndStartDate } from "../../../utilities/ValidationForEndDateAndStartDate";
import startDefaultDate from "../../../utilities/CurrentDate";
import {
  SetelmentsReportDropdown,
  SettelmentReportsData,
  SettelmentTableview,
} from "../../../store/Settelments";
// <-----------------------------------Redux Action Imports From Redux Store----------------------------------------------------------->

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//<-------------------------------------------------The  FilterForAllDeditReports Starts HERE------------------------------------------>

const FilterForSettelmentsReport = (props) => {
  //<-------------------------------------------------DECLARATIONS-------------------------------------------------------------->

  const startDate = useMuiFormField(startDefaultDate, "date"); //This is a TextField Generated From useMuiFormField (customHook)
  const endDate = useMuiFormField(startDefaultDate, "date"); //This is a TextField Generated From useMuiFormField (customHook)
  const dispatch = useDispatch(); // This is a declaration For using useDispatch Hook as dispatch keyword
  const [DropDownList, setDropDownList] = useState([]);
  const currentUser = useSelector((state) => state.auth.currentUser);

  //<-------------------------------------------------UseEffect Hook---------------------------------------------------------->
  useEffect(() => {
    dispatch(SetelmentsReportDropdown()).then((res) =>
      setDropDownList(res.payload)
    );
    dispatch(SettelmentTableview(false));
    dispatch(SettelmentReportsData(filterData)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(SettelmentTableview(true));
      } else {
        dispatch(SettelmentTableview(false));
      }
    });
    return () => {};
  }, []);
  const mapperForFilterFieldsAndStateObject = {
    //This is a variable that Stores an object with Some data on Debit Transactios
    "Settlement ClientList": {
      stateHandler: useMuiFormField("", "text"),
      menuList: [{ code: "ALL", description: "ALL" }, ...DropDownList],
    },
  };

  const filterData = {
    //This is the filterData that is passed as an argument above in the Dispatched Action
    p_merchant_code:
      currentUser.filter_id === 0
        ? mapperForFilterFieldsAndStateObject["Settlement ClientList"]
            .stateHandler.value === ""
          ? "ALL"
          : mapperForFilterFieldsAndStateObject["Settlement ClientList"]
              .stateHandler.value
        : currentUser.merchant_id,
    p_fromDate:
      startDefaultDate === undefined ? startDefaultDate : startDate.value,
    p_toDate: startDefaultDate === undefined ? startDefaultDate : endDate.value,
  };

  const handleClickForSubmit = () => {
    //This is a function That runs when Submit/Search Button is clicked
    dispatch(SettelmentTableview(false)); //This Decides if the table should be shown or show loading
    let validationResponse = ValidationForEndDateAndStartDate(
      //This function Takes Start and End date objects

      startDate,
      endDate
    );
    if (validationResponse["toProceed"]) {
      //If the validation Response is True Then  the below code Block will run

      dispatch(SettelmentReportsData(filterData)).then(
        //This Action Returns A promise and takes an argument as An Object
        (onResolved) => {
          //If The Promise Is Resolved Then this Code block will run

          if (onResolved.meta.requestStatus === "fulfilled") {
            dispatch(SettelmentTableview(true));
          } else {
            dispatch(SettelmentTableview(false));
          }
        }
        // (onRejected) => {
        //   //If The Promise Is Rejected Then this Code block will run
        //   dispatch(SettelmentTableview(false));
        // }
      );
    }
  };
  const handleClickForReset = () => {
    startDate.setValue(startDefaultDate);
    endDate.setValue(startDefaultDate);
    dispatch(SettelmentTableview(false));
    dispatch(
      SettelmentReportsData({
        p_merchant_code:
          currentUser.filter_id === 0 ? "ALL" : currentUser.merchant_id,
        p_fromDate: startDefaultDate,
        p_toDate: startDefaultDate,
      })
    ).then(dispatch(SettelmentTableview(true)));
    //This is a function That runs when Reset Button is clicked
    //This Functin Resets the data in filter fields to default and set start and end Dates to Current date
  };

  //////////////////////////////////////////////////RETURN SECTION //////////////////////////////////////////////////////////////
  return (
    <Grid container xs={12} spacing={4}>
      {currentUser.filter_id === 0 &&
        Object.keys(mapperForFilterFieldsAndStateObject).map((filterField) => {
          return (
            <Grid item xs={12} md={3}>
              <NativeSelectField
                name={filterField}
                labelName={filterField}
                labelId={`${filterField}-filter-for-all-mandate-reports`}
                selectFieldId={`${filterField}-filter-for-all-mandate-reports_1`}
                stateHandler={
                  mapperForFilterFieldsAndStateObject[filterField].stateHandler
                }
                menuItemsList={
                  mapperForFilterFieldsAndStateObject[filterField].menuList
                }
              />
            </Grid>
          );
        })}
      {/* <Grid item xs={12} md={3}></Grid> */}

      <Grid item xs={12} md={3}>
        <DatePickerField
          labelName="From Date"
          idProps="FromDate"
          defaultValue={startDefaultDate}
          stateHandler={startDate}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <DatePickerField
          labelName="To  Date"
          idProps="ToDate"
          defaultValue={startDefaultDate}
          stateHandler={endDate}
        />
      </Grid>

      {currentUser.filter_id === 0 && <Grid item md={3}></Grid>}
      <Grid item xs={12} md={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickForSubmit}
        >
          Search
        </Button>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickForReset}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterForSettelmentsReport;

import React from "react";

import QRCode from "qrcode.react";
import config from "../../config";

export default function QRCodeGenerator(props) {
  return (
    <div>
      <QRCode
        // value={`${config.uploadFileUrl}?qrMandateReqId=${props.valueToEncode}`}
        value={props.valueToEncode}
      />
    </div>
  );
}

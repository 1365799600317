import React from "react";
import { makeStyles } from "@material-ui/core/";
import { Fragment } from "react";
import { RFC_2822 } from "moment";

const useStyles = makeStyles((theme) => ({
  container:{
    display:'flex',
    alignItems:'center'
  },
  label:{
    marginRight:'10px',
    fontSize:'small',
    width:'30px'
  },
  letter: {
    width: "15px",
    border: "1px solid black",
    textAlign: "center"
  },
  date: {
    display: "flex",
    direction:"column"
  },
  dateContent: {
    margin: "0px 2px"
  }
}));

export default function IndividualCharBoxDateField(props) {
  const classes = useStyles();
  const date = props.value?props.value.split("-"):null;

  function BoxDateField(){
    if(date===null){
      return(
        <Fragment>
                <div className={classes.dateContent}>
              <input
                type="text"
                className={classes.letter}
                maxLength="1"
                key="D1"
                value=""
                disabled
              />
              <input
                type="text"
                className={classes.letter}
                maxLength="1"
                key="D2"
                value=""
                disabled
              />
          </div>
          <div className={classes.dateContent}>
              <input
                type="text"
                className={classes.letter}
                maxLength="1"
                key="M1"
                value=""
                disabled
              />
              <input
                type="text"
                className={classes.letter}
                maxLength="1"
                key="M2"
                value=""
                disabled
              />
          </div>
          <div className={classes.dateContent}>
              <input
                type="text"
                className={classes.letter}
                maxLength="1"
                key="Y1"
                value=""
                disabled
              />
              <input
                type="text"
                className={classes.letter}
                maxLength="1"
                key="Y2"
                value=""
                disabled
              />
              <input
                type="text"
                className={classes.letter}
                maxLength="1"
                key="Y3"
                value=""
                disabled
              />
              <input
                type="text"
                className={classes.letter}
                maxLength="1"
                key="Y4"
                value=""
                disabled
              />
          </div>
        </Fragment>
      )
    }else{
      return(
        <Fragment>
          <div className={classes.dateContent}>
            {
            date[2].split("").map((textletter, index) => (
              <input
                type="text"
                name="letter1"
                className={classes.letter}
                maxLength="1"
                key={index}
                value={textletter}
                disabled
              />
            ))}
          </div>
          <div className={classes.dateContent}>
            {date[1].split("").map((textletter, index) => (
              <input
                type="text"
                name="letter1"
                className={classes.letter}
                maxLength="1"
                key={index}
                value={textletter}
                disabled
              />
            ))}
          </div>
          <div className={classes.dateContent}>
            {date[0].split("").map((textletter, index) => (
              <input
                type="text"
                name="letter1"
                className={classes.letter}
                maxLength="1"
                key={index}
                value={textletter}
                disabled
              />
            ))}
          </div>
        </Fragment>
      )
    }
  }

  return (
    <div className={classes.container}>
      <label className={classes.label}>{props.label}</label>
      <div className={classes.date}>
        {
          BoxDateField()
        }

      </div>
    </div>
  );
}

let currentDate = new Date();
// console.log(currentDate.getMonth())
const currentMonth =
  currentDate.getMonth() < 10
    ? currentDate.getMonth() ===9?`${currentDate.getMonth() + 1  }`:`0${currentDate.getMonth() + 1  }`
    : currentDate.getMonth();
const currentDate1 =
  currentDate.getDate() < 10
    ? `0${currentDate.getDate()}`
    : currentDate.getDate();
const startDefaultDate = `${currentDate.getFullYear()}-${currentMonth}-${currentDate1}`;

export default startDefaultDate
// This is a Protected route that only wraps and return a Route component if a condition is satisfied i.e current user is present.
import React from "react";
import { Route } from "react-browser-router";
// import { Redirect } from "react-router-dom";
// import config from "../config";
import { useDispatch, useSelector } from "react-redux";

// import { withRouter } from "react-browser-router";
import { setCurrentUserFromCookies } from "../store/auth";
import { isObjectEmpty } from "../utilities/isObjectEmpty";
import { getCookies } from "../utilities/getCookies";

const ProtectedRouteWrapper = ({
  isSessionPresent,
  isCurrentUserPresentInState,
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isObjectEmpty(currentUser)) {
          if (getCookies().currentUser !== undefined) {
            dispatch(
              setCurrentUserFromCookies({
                currentUser: JSON.parse(getCookies().currentUser),
              })
            );
            return (
              <Layout {...rest}>
                <Component {...rest} />
              </Layout>
            );
          } else {
            return window.location.replace(`${window.location.origin}/login`);
          }
        } else {
          return (
            <Layout {...rest}>
              <Component {...rest} />
            </Layout>
          );
        }
      }}
    />
  );
};

export default ProtectedRouteWrapper;

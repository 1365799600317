import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";
import { generateUniqueId } from "../utilities/generateUniqueId";
import listDateToString from "../utilities/listDateToString";

const formsResetToInitialState = {
  clientRegistrationId: "",
  //mandate Details
  applicantType: "merchant",
  mandateVariant: "NA",

  // mandateVariant: "PHYSICAL",

  mandatePurpose: "NA",
  mandateCategoryCode: "",
  mandateMaxAmount: "",
  frequency: "",
  fixedmaxAmount: "NA",
  emiamount: "",

  consumerRefNumber: "",
  schemaRefNunber: "",
  startDate: null,
  endDate: null,
  untilCancelled: false,
  // endDateUntilChecked: {
  //   untilChecked: [],
  //   endDate: null,
  // },
  requestType: "",
  mappingWithFormikStates: {
    //mandate details
    applicantType: "Requested by",
    mandateVariant: "Mandate Variant",
    mandatePurpose: "Mandate Purpose",
    mandateMaxAmount: "ECS Amount",
    frequency: "Frequency",
    fixedmaxAmount: "Fixed/Maximum Amount",
    emiamount: "EMI Amount",

    consumerRefNumber: "Consumer Reference Number",
    schemaRefNunber: "Scheme Reference Number",
    startDate: "Start date",
    endDate: "End date",
    untilCancelled: "Until Cancelled",
    requestType: "Request Type",
    //Personal Details
    userName: "Name",
    userEmail: "Email",
    mobileNumber: "Moblie Number",
    teleNumber: "Telephone Number",
    additionalDetail: "Additional Details",
    panNumber: "PAN Number",

    //Bank Details
    bankName: "Bank Name",
    accountNumber: "Account Number",
    accountType: "Account Type",
    ifscCode: "IFSC Code",
    authMode: "Authentication Mode",
  },
  mandateDetailsFields: [
    "applicantType",
    "mandateVariant",
    "mandatePurpose",
    "mandateMaxAmount",
    "frequency",
    "fixedmaxAmount",
    "emiamount",
    "consumerRefNumber",
    "schemaRefNunber",
    "startDate",
    "endDate",
    // "endDateUntilChecked",
    "untilCancelled",
    "requestType",
  ],

  //personal Details
  // userName: "",
  userName: {
    prefix: "",
    maidenName: "",
  },
  userEmail: "",
  // mobileNumber: "",
  mobileNumber: {
    countryCode: "91",
    number: "",
  },
  teleNumber: {
    countryCode: "91",
    stdCode: "011",
    number: "",
  },
  panNumber: "",
  personalDetailsFields: [
    "userName",
    "userEmail",
    "mobileNumber",
    "teleNumber",
    "additionalDetail",
    "panNumber",
  ],

  // bank details
  bankName: "NA",
  accountNumber: "",
  accountType: "",
  ifscCode: "",
  authMode: "",
  bankDetailsFields: [
    "bankName",
    "accountNumber",
    "accountType",
    "ifscCode",
    "authMode",
  ],

  summaryDetailsFields: ["mandateVariant", "userName", "bankName"],

  //Mandate Authorization step
  authenticationMode: "",
  //this was used when we were replacing submit button with view button on successull registration of physical mandate---Not used now
  // physicalMandateResponse: "",

  consentConfirmed: false,

  caseForAdHocMandate: false,
  bankDetailsSavedForAdHocMandate: false,
};

const onlineEmandate = {
  forms: {
    //payout request unique id generator

    clientRegistrationId: "",
    //mandate Details
    applicantType: "merchant",
    mandateVariant: "NA",
    mandateCategoryCode: "",
    // mandateVariant: "PHYSICAL",
    // mandateVariant: "ONLINE",
    mandatePurpose: "NA",
    mandateMaxAmount: "",
    frequency: "NA",
    fixedmaxAmount: "NA",
    consumerRefNumber: "",
    schemaRefNunber: "",
    startDate: null,
    endDate: null,
    untilCancelled: false,
    emiamount: "",
    // endDateUntilChecked: {
    //   untilChecked: [],
    //   endDate: null,
    // },
    requestType: "NA",
    mappingWithFormikStates: {
      //mandate details
      applicantType: "Requested by",
      mandateVariant: "Mandate Variant",
      mandatePurpose: "Mandate Purpose",
      mandateMaxAmount: "ECS Amount",
      emiamount: "EMI Amount",
      frequency: "Frequency",
      fixedmaxAmount: "Fixed/Maximum Amount",
      consumerRefNumber: "Consumer Reference Number",
      schemaRefNunber: "Scheme Reference Number",
      startDate: "Start date",
      endDate: "End date",
      untilCancelled: "Until Cancelled",
      // endDateUntilChecked:"End date",
      requestType: "Request Type",
      //Personal Details
      userName: "Name",
      userEmail: "Email",
      mobileNumber: "Moblie Number",
      teleNumber: "Telephone Number",
      additionalDetail: "Additional Details",
      panNumber: "PAN Number",

      //Bank Details
      bankName: "Bank Name",
      accountNumber: "Account Number",
      accountType: "Account Type",
      ifscCode: "IFSC Code",
      authMode: "Authentication Mode",
    },
    mandateDetailsFields: [
      "applicantType",
      "mandateVariant",
      "mandatePurpose",
      "mandateMaxAmount",
      "frequency",
      "fixedmaxAmount",
      "emiamount",
      "consumerRefNumber",
      "schemaRefNunber",
      "startDate",
      "endDate",
      // "endDateUntilChecked",
      "untilCancelled",
      "requestType",
    ],

    //personal Details
    // userName: "",
    userName: {
      prefix: "",
      maidenName: "",
    },
    userEmail: "",
    // mobileNumber: "",
    mobileNumber: {
      countryCode: "",
      number: "",
    },
    teleNumber: {
      countryCode: "91",
      stdCode: "011",
      number: "",
    },
    // altMobileNumber: {
    //   countryCode: "",
    //   number: "",
    // },
    panNumber: "",
    personalDetailsFields: [
      "userName",
      "userEmail",
      "mobileNumber",
      "teleNumber",
      "additionalDetail",
      "panNumber",
    ],

    // bank details
    bankName: "NA",
    accountNumber: "",
    accountType: "",
    ifscCode: "",
    authMode: "NA",
    bankDetailsFields: [
      "bankName",
      "accountNumber",
      "accountType",
      "ifscCode",
      "authMode",
    ],

    summaryDetailsFields: ["mandateVariant", "userName", "bankName"],

    //Mandate Authorization step
    authenticationMode: "",

    consentConfirmed: false,
    //For AdHoc Case
    caseForAdHocMandate: false,
    bankDetailsSavedForAdHocMandate: false,
  },

  dropdownFieldsData: {
    frequencyDropDownList: [],
    mandateVariantDropDownList: [],
    mandatePurposeDropDownList: [],
    requestTypeDropDownList: [],
    // mandateCategoryDropDownList:[]
    bankNameDropDownList: [],
  },
  payoutRequestChildWindow: {
    window: "",
  },
  // physicalMandateResponse: "",
  status: "idle",
  error: "",
};

export const fetchRandomApi = createAsyncThunk(
  "onlineEmandate/fetchFormDropdownsData",
  async () => {
    const response = await Axios.get(
      "https://jsonplaceholder.typicode.com/posts"
    );
    return response.data;
  }
);

export const fetchGetFrequencyDropdown = createAsyncThunk(
  "onlineEmandate/fetchGetFrequencyDropdown",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/frequency`
    );
    return response.data;
  }
);

export const fetchMandateTypeDropdown = createAsyncThunk(
  "onlineEmandate/fetchMandateTypeDropdown",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/MandateType`
    );
    return response.data;
  }
);

export const fetchMandatePurposeDropdown = createAsyncThunk(
  "onlineEmandate/fetchMandatePurposeDropdown",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/MandateCategory`
    );
    return response.data;
  }
);

export const fetchRequestTypeDropdown = createAsyncThunk(
  "onlineEmandate/fetchRequestTypeDropdown",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/RequestType`
    );
    return response.data;
  }
);

// export const fetchMandateCategoryDropdown = createAsyncThunk(
//   "onlineEmandate/fetchMandateTypeDropdown",
//   async () => {
//     const response = await Axios.get(
//       `${config.proxy}${config.backendUrl}/getMandateCategory`
//     );
//     return response.data;
//   }
// );

export const fetchBankNameDropdown = createAsyncThunk(
  "onlineEmandate/fetchBankNameDropdown",
  async (requestParam) => {
    const response = await Axios.get(
      // `${config.proxy}https://enach.npci.org.in/apiservices/getLiveBankDtls`
      `${config.backendUrl}/REST/GetCommonData/0/${requestParam.authMode}`
      // `https://subscription.sabpaisa.in/subscriptionAPI/REST/GetCommonData/0/nb`
    );
    return response.data;
  }
);

// Final Step for payout request for mandate
export const processMandatePayoutRequest = createAsyncThunk(
  "onlineEmandate/processMandatePayoutRequest",
  async (requestParam) => {
    const response = await Axios.post(
      // "http://192.168.34.61:8081/subscription/registration",
      `${config.backendUrl}/registration`,
      requestParam
    );
    return response.data;
  }
);

//Final Step for physical Mandate
export const processMandatePhysicalPayoutRequest = createAsyncThunk(
  "onlineEmandate/processPhysicalMandateRequest",
  async (requestParam) => {
    const response = await Axios.post(
      // "http://192.168.34.61:8081/subscription/physicalRegistration",
      `${config.backendUrl}/physicalRegistration`,
      requestParam
    );
    return response.data;
  }
);

///Reducers for AdHoc Case ---Dynamic Link

export const fetchFilledDetailsByMerchantForAdHoc = createAsyncThunk(
  "onlineEmandate/fetchFilledDetailsByMerchantForAdHoc",
  async (requestParam) => {
    const response = await Axios.get(
      `${config.backendUrl}/npci/registration/status/${requestParam.mandateRegId}`,
      // `http://192.168.34.61:8081/subscription/npci/registration/status/${requestParam.mandateRegId}`,
      requestParam
    );

    return response.data;
  }
);

///Reducers for AdHoc Case ---Merchant on Click Generate Link

export const generateLinkFormMerchantDetails = createAsyncThunk(
  "onlineEmandate/generateLinkFormMerchantDetails",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/physicalRegistration`,
      // "http://192.168.34.61:8081/subscription/physicalRegistration",
      requestParam
    );

    return response.data;
  }
);

///Reducers to Generate link for Retrying Payment ---Merchant on Click Generate Link

export const generateLinkToRetryPayment = createAsyncThunk(
  "onlineEmandate/generateLinkToRetryPayment",
  async (requestParam) => {
    const response = await Axios.post(
      `https://sp-test-sabpaisa.herokuapp.com/sabpaisaPayment`,
      requestParam
    );

    return response.data;
  }
);

//// Message senging Api
export const sendGeneratedLinkOrMessageThroughMessage = createAsyncThunk(
  "sendGeneratedLinkOrMessageThroughMessage",
  async (requestParam) => {
    const response = await Axios.get(
      `https://api.msg91.com/api/sendhttp.php?sender=SPTRAN&route=4&mobiles=${requestParam.mobilenumber}&authkey=177009ASboH8XM59ce18cb&country=91&message=${requestParam.message}`,
      requestParam
    );

    return response.data;
  }
);

//// Get IFSC code confirmation
export const GetIFSCCodeConfirmation = createAsyncThunk(
  "GetIFSCCodeConfirmation",
  async (requestParam) => {
    const response = await Axios.get(
      `https://ifsc.razorpay.com/${requestParam}`
    );

    return response;
  }
);

// Mail sending Api
export const sendGeneratedLinkOrMessageThroughEmail = createAsyncThunk(
  "sendGeneratedLinkOrMessageThroughEmail",
  async (requestParam) => {
    const response = await Axios.post(
      `https://adminapi.sabpaisa.in/REST/Email/sendEmail`,
      requestParam
    );

    return response.data;
  }
);

// viewSelected Slice----

const onlineEmandateSlice = createSlice({
  name: "onlineEmandate",
  initialState: onlineEmandate,
  reducers: {
    onlineMandateRegistrationCanceled: (onlineEmandate, action) => {},
    payoutRequestReset: (onlineEmandate, action) => {
      onlineEmandate.forms = formsResetToInitialState;
    },
    mandatePayoutRequestIdAdded: (onlineEmandate, action) => {
      onlineEmandate.forms.clientRegistrationId = action.payload.mandateReqId;
    },
    allFormSubmitted: (onlineEmandate, action) => {
      onlineEmandate.forms = action.payload.formDetails;
    },
    mandateDetailsFormSubmitted: (onlineEmandate, action) => {
      Object.keys(action.payload).map(
        (key) => (onlineEmandate.forms[key] = action.payload[key])
      );
      // onlineEmandate.forms = { ...onlineEmandate.forms ,...action.payload };
    },
    personalDetailsFormSubmitted: (onlineEmandate, action) => {
      Object.keys(action.payload).map(
        (key) => (onlineEmandate.forms[key] = action.payload[key])
      );
      // onlineEmandate.forms = { ...action.payload };
    },
    bankDetailsFormSubmitted: (onlineEmandate, action) => {
      Object.keys(action.payload).map(
        (key) => (onlineEmandate.forms[key] = action.payload[key])
      );

      // onlineEmandate.forms = { ...action.payload };
    },

    //process Mandate Payout Request

    processMandatePayoutRequestDummmy: (onlineEmandate, action) => {
      var myWindow = window.open("", "", "width=200,height=100");
      myWindow.document.write("<p>I replaced the current window.</p>");
    },
    addDocumentToWindowDummmy: (onlineEmandate, action) => {
      onlineEmandate.payoutRequestChildWindow.window.document.write(
        "<p>This is 'MsgWindow'. I am 200px wide and 100px tall!</p>"
      );
    },

    //For AdHocCase
    settingCaseForAdHocMandate: (onlineEmandate, action) => {
      onlineEmandate.forms.caseForAdHocMandate =
        action.payload.caseForAdHocMandate;
    },
    settingBankDetailsSavedForAdHocMandate: (onlineEmandate, action) => {
      onlineEmandate.forms.bankDetailsSavedForAdHocMandate =
        action.payload.bankDetailsSavedForAdHocMandate;
    },
  },
  extraReducers: {
    [fetchRandomApi.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchRandomApi.fulfilled]: (state, action) => {
      state.dropdownFieldsData = action.payload;
    },
    [fetchRandomApi.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    // fetchGetFrequencyDropdown--

    [fetchGetFrequencyDropdown.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchGetFrequencyDropdown.fulfilled]: (state, action) => {
      state.dropdownFieldsData.frequencyDropDownList = action.payload;
    },
    [fetchGetFrequencyDropdown.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    // fetchMandateTypeDropdown--

    [fetchMandateTypeDropdown.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchMandateTypeDropdown.fulfilled]: (state, action) => {
      state.dropdownFieldsData.mandateVariantDropDownList = action.payload;
    },
    [fetchMandateTypeDropdown.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    // fetchMandatePurposeDropdown--

    [fetchMandatePurposeDropdown.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchMandatePurposeDropdown.fulfilled]: (state, action) => {
      state.dropdownFieldsData.mandatePurposeDropDownList = action.payload;
    },
    [fetchMandatePurposeDropdown.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    // fetchRequestTypeDropdown--

    [fetchRequestTypeDropdown.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchRequestTypeDropdown.fulfilled]: (state, action) => {
      state.dropdownFieldsData.requestTypeDropDownList = action.payload;
    },
    [fetchRequestTypeDropdown.rejected]: (state, action) => {
      state.status = "failed";

      state.error = action.error.message;
    },

    /// SenMessageToMobile
    [sendGeneratedLinkOrMessageThroughMessage.pending]: (state, action) => {
      state.status = "pending";
    },
    [sendGeneratedLinkOrMessageThroughMessage.fulfilled]: (state, action) => {
      // state.sendGeneratedLinkOrMessageThroughMessage = action.payload;
    },
    [sendGeneratedLinkOrMessageThroughMessage.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    ////// SendMessageWithEmail
    [sendGeneratedLinkOrMessageThroughEmail.pending]: (state, action) => {
      state.status = "pending";
    },
    [sendGeneratedLinkOrMessageThroughEmail.fulfilled]: (state, action) => {
      // state.sendGeneratedLinkOrMessageThroughMessage = action.payload;
    },
    [sendGeneratedLinkOrMessageThroughEmail.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    // fetchMandateCategoryDropdown--

    // [fetchMandateCategoryDropdown.pending]: (state, action) => {
    //   state.status = "pending";
    // },
    // [fetchMandateCategoryDropdown.fulfilled]: (state, action) => {
    //   state.dropdownFieldsData.mandateCategoryDropDownList = action.payload;
    // },
    // [fetchMandateCategoryDropdown.rejected]: (state, action) => {
    //   state.status = "failed";
    //   state.error = action.error.message;
    // },

    // fetchBankNameDropdown--

    [fetchBankNameDropdown.pending]: (state, action) => {
      //Resetting bankName

      state.status = "pending";
    },
    [fetchBankNameDropdown.fulfilled]: (state, action) => {
      state.dropdownFieldsData.bankNameDropDownList = action.payload;
    },
    [fetchBankNameDropdown.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //processMandatePayoutRequest

    [processMandatePayoutRequest.pending]: (state, action) => {
      state.status = "pending";
    },
    [processMandatePayoutRequest.fulfilled]: (state, action) => {
      state.status = "fulfilled";
    },
    [processMandatePayoutRequest.rejected]: (state, action) => {
      // alert("inside reducer---error in API");
      state.status = "failed";
      state.error = action.error.message;
    },

    //Physical Mandate Reqest
    [processMandatePhysicalPayoutRequest.pending]: (state, action) => {
      state.status = "pending";
    },
    [processMandatePhysicalPayoutRequest.fulfilled]: (state, action) => {
      state.status = "fulfilled";
    },
    [processMandatePhysicalPayoutRequest.rejected]: (state, action) => {
      // alert("inside reducer---error in API");
      state.status = "failed";
      state.error = action.error.message;
    },

    ///

    [fetchFilledDetailsByMerchantForAdHoc.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchFilledDetailsByMerchantForAdHoc.fulfilled]: (state, action) => {
      // state.physicalMandateResponse = action.payload;

      // This will not be unique for 2nd time in case of adhoc
      // state.forms.clientRegistrationId = action.payload.clientRegistrationId;
      state.forms.clientRegistrationId = generateUniqueId();
      //mandate Details
      // state.forms.applicantType = "applicant";
      state.forms.applicantType = action.payload.userType;
      state.forms.mandateVariant = action.payload.mandateType;
      state.forms.mandateCategoryCode = action.payload.mandateCategoryCode;
      state.forms.mandatePurpose = action.payload.mandatePurpose;
      state.forms.mandateMaxAmount = action.payload.mandateMaxAmount.slice(
        0,
        -3
      );
      state.forms.emiamount = action.payload.emiamount;

      state.forms.frequency = action.payload.frequency;
      state.forms.fixedmaxAmount =
        action.payload.fixedmaxAmount !== undefined
          ? action.payload.fixedmaxAmount
          : "Fixed";
      state.forms.consumerRefNumber = action.payload.consumerReferenceNumber;
      state.forms.schemaRefNunber = action.payload.schemeReferenceNumber;
      state.forms.untilCancelled = action.payload.untilCancelled;
      state.forms.startDate = new Date(
        listDateToString(action.payload.mandateStartDate)
      );
      state.forms.endDate = action.payload.untilCancelled
        ? null
        : new Date(listDateToString(action.payload.mandateEndDate));
      state.forms.requestType = action.payload.requestType;

      //Personal Details
      state.forms.userName.maidenName = action.payload.payerName;
      state.forms.userEmail = action.payload.payerEmail;
      state.forms.mobileNumber.countryCode = "91";
      state.forms.mobileNumber.number =
        action.payload.payerMobile.split("-")[1];

      state.forms.teleNumber.countryCode = "91";
      state.forms.teleNumber.stdCode = "011";
      state.forms.teleNumber.number = action.payload.telePhone.split("-")[2];
      state.forms.panNumber = action.payload.panNo;
    },

    [fetchFilledDetailsByMerchantForAdHoc.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    //Reducers for AdHoc Case ---Merchant on Click Generate Link
    [generateLinkFormMerchantDetails.pending]: (state, action) => {
      state.status = "pending";
    },
    [generateLinkFormMerchantDetails.fulfilled]: (state, action) => {
      state.status = "fulfilled";
    },
    [generateLinkFormMerchantDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    ///Reducers to Generate link for Retrying Payment ---Merchant on Click Generate Link
    [generateLinkToRetryPayment.pending]: (state, action) => {
      state.status = "pending";
    },
    [generateLinkToRetryPayment.fulfilled]: (state, action) => {
      state.status = "fulfilled";
    },
    [generateLinkToRetryPayment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

// Exporting onlineEmandate actions
export const {
  allFormSubmitted,
  mandateDetailsFormSubmitted,
  personalDetailsFormSubmitted,
  bankDetailsFormSubmitted,
  processMandatePayoutRequestDummmy,
  addDocumentToWindowDummmy,
  mandatePayoutRequestIdAdded,
  payoutRequestReset,
  onlineMandateRegistrationCanceled,
  settingCaseForAdHocMandate,
  settingBankDetailsSavedForAdHocMandate,
} = onlineEmandateSlice.actions;

// Exporting slice reducer
export default onlineEmandateSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";

const debitRequest = {
  allDebitReports: [],
  MerchantList: [],
  allDebitRequestTableView: { showDebitRequestsTable: false },
};

export const createDebitRequest = createAsyncThunk(
  "DebitRequest/CreateDebitRequest",
  async (reqParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/createDebitRequest`,
      { reqParam }
    );
    return response.data;
  }
);
export const createDebitRequestInBulk = createAsyncThunk(
  "DebitRequest/createDebitRequestInBulk",
  async (reqParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/createDebitRequestInBulk`,
      reqParam
    );
    return response.data;
  }
);
export const getAllDebitRequests = createAsyncThunk(
  "DebitRequest/getAllDebitRequests",
  async (reqParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/getAllDebitRequests`,
      reqParam
    );
    return response.data;
  }
);

export const getMerchantCode = createAsyncThunk(
  "DebitRequest/getMerchantCode",
  async (reqParam) => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/getMerchant`
    );
    return response.data;
  }
);
export const getDebitRequestById = createAsyncThunk(
  "DebitRequest/getDebitRequestById",
  async (reqParam) => {
    const response = await Axios.get(
      `${config.backendUrl}/getAllDebitRequests/${reqParam}`
    );
    return response.data;
  }
);
export const approveAll = createAsyncThunk(
  "DebitRequest/approveAll",
  async (reqParam) => {
    const response = await Axios.put(
      `${config.backendUrl}/approveAll/`,
      reqParam
    );
    return response.data;
  }
);
export const approveById = createAsyncThunk(
  "DebitRequest/approveById",
  async (reqParam) => {
    const response = await Axios.put(`${config.backendUrl}/approve/`, reqParam);
    return response.data;
  }
);
export const updateDebitRequest = createAsyncThunk(
  "DebitRequest/updateDebitRequest",
  async (reqParam) => {
    const response = await Axios.put(
      `${config.backendUrl}/updateDebitRequest`,
      reqParam
    );
    return response.data;
  }
);

const debitRequestSlice = createSlice({
  name: "DebitRequest",
  initialState: debitRequest,
  reducers: {
    showDebitRequestsTable: (debitRequest, action) => {
      debitRequest.allDebitRequestTableView.showDebitRequestsTable =
        action.payload;
    },
    clearDebitRequests: (debitRequest, action) => {
      debitRequest.allDebitReports = [];
    },
  },

  extraReducers: {
    ////////////createDebitRequest/////////////////////////
    [createDebitRequest.pending]: (state, action) => {
      state.status = "pending";
    },
    [createDebitRequest.fulfilled]: (state, action) => {
      state.status = "fullfilled";
    },
    [createDebitRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    ////////////getAllDebitRequests/////////////////////////
    [getAllDebitRequests.pending]: (state, action) => {
      state.status = "pending";
    },
    [getAllDebitRequests.fulfilled]: (state, action) => {
      state.status = "fullfilled";
      state.allDebitReports = action.payload;
    },
    [getAllDebitRequests.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    ////////////Get Merchant Code DropDownList/////////////////////////
    [getMerchantCode.pending]: (state, action) => {
      state.status = "pending";
    },
    [getMerchantCode.fulfilled]: (state, action) => {
      state.status = "fullfilled";
      state.MerchantList = action.payload;
    },
    [getMerchantCode.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    ////////////getDebitRequestById/////////////////////////
    [getDebitRequestById.pending]: (state, action) => {
      state.status = "pending";
    },
    [getDebitRequestById.fulfilled]: (state, action) => {
      state.status = "fullfilled";
    },
    [getDebitRequestById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    ////////////approveAll/////////////////////////
    [approveAll.pending]: (state, action) => {
      state.status = "pending";
    },
    [approveAll.fulfilled]: (state, action) => {
      state.status = "fullfilled";
    },
    [approveAll.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    ////////////approveById/////////////////////////
    [approveById.pending]: (state, action) => {
      state.status = "pending";
    },
    [approveById.fulfilled]: (state, action) => {
      state.status = "fullfilled";
    },
    [approveById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});
export const { showDebitRequestsTable, clearDebitRequests } =
  debitRequestSlice.actions;
export default debitRequestSlice.reducer;

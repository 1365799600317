import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";
import listTransform from "../utilities/listTransform";

const reportsData = {
  allMandateList: [],
  allCancelMandateList: [],
  allDebitList: [],
  allCancelMandateListFiltered: [],
  allDebitListFiltered: {
    records: [],
    count: 0,
  },
  allPendingMandateList: [],
  allMandateListFiltered: {
    records: [],
    count: 0,
  },
  allPendindMandateListFiltered: {
    records: [],
    count: 0,
  },
  allPendingMandateList: [],
  uiState: {
    allMandatesViewMaterialtable: { tableShouldBeShown: false },
    allCancelMandatesViewMaterialtable: { tableShouldBeShown: false },
    allDebitsViewMaterialtable: { tableShouldBeShown: false },
    allDebitsTXNMaterialtable: { tableShouldBeShown: false },
    allNextDebitTxnNMaterialtable: { tableShouldBeShown: false },
  },
  DebitTxnFileReports: [],
  NextDebitTxnFileReports: [],
  getPhyMandateXLResponse: [],
};

export const fetchRandomApi = createAsyncThunk(
  "selectedView/fetchRandomApi",
  async () => {
    const response = await Axios.get(
      "https://jsonplaceholder.typicode.com/posts"
    );
    return response.data;
  }
);

export const fetchAllMandateListData = createAsyncThunk(
  "reportsData/fetchAllMandateListData",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterRegistration`,
      requestParam
    );
    return response.data.filter(
      (item) =>
        item.bankName !== null && item.regestrationStatus !== "INITIATED"
    );
  }
);

export const fetchAllPendingMandateListData = createAsyncThunk(
  "reportsData/fetchAllPendingMandateListData",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterRegistration`,
      requestParam
    );
    return response.data.filter(
      (item) =>
        item.bankName === null || item.regestrationStatus === "INITIATED"
    );
  }
);

export const fetchAllCancelMandateListData = createAsyncThunk(
  "reportsData/fetchAllCancelMandateListData",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/filterregistrationCancellation`,
      requestParam
    );
    return response.data;
  }
);

export const fetchAllDebitListData = createAsyncThunk(
  "reportsData/fetchAllDebitListData",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterDebitReport`,

      requestParam
    );
    return response.data;
  }
);

export const getPhyMandateXL = createAsyncThunk(
  "reportsData/getPhyMandateXL",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/getPhyMandateXL`,

      { p_fromDate: requestParam.toISOString().slice(0, 10) }
    );
    return response.data;
  }
);
export const filterMandateReportWithFilters = createAsyncThunk(
  "reportsData/filterMandateReportWithFilters",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterMandateReport`,
      requestParam
    );
    return response.data.records.filter(
      (item) =>
        item.bankName !== null && item.regestrationStatus !== "INITIATED"
    );
  }
);

export const filterPendingMandateReportWithFilters = createAsyncThunk(
  "reportsData/filterPendingMandateReportWithFilters",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterMandateReport`,
      requestParam
    );
    return response.data.records.filter(
      (item) =>
        item.bankName === null || item.regestrationStatus === "INITIATED"
    );
  }
);

export const filterCancelMandateReportWithFilters = createAsyncThunk(
  "reportsData/filterCancelMandateReportWithFilters",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/filterregistrationCancellation`,
      requestParam
    );
    return response.data;
  }
);

export const filterDebitReportWithFilters = createAsyncThunk(
  "reportsData/filterDebitReportWithFilters",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterDebitReport`,
      requestParam
    );
    return response.data;
  }
);

//Update Mandate Cancellation Status(under Cancel Mandate Reports)
export const updateRegistrationCancellation = createAsyncThunk(
  "reportsData/updateRegistrationCancellation",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}API/updateRegistrationCancellation`,
      requestParam
    );
    return response.data;
  }
);

// Approve Cancelled Mandate by Merchant
export const approveCancelMandate = createAsyncThunk(
  "reportsData/approveCancelMandate",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/approveCancelMandate`,
      {
        mandatecancellationid: requestParam.mandatecancellationid,
        isApproved: 1,
      }
    );
    return response.data;
  }
);

//DebitTxnFiLE aPI REPORT
export const DebitTxnFileReports = createAsyncThunk(
  "reportsData/DebitTxnFileReports",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/DebitTxnFile`,
      requestParam
    );
    return response.data;
  }
);
//Next Debit Report APi
export const NextDebitTxnFileReports = createAsyncThunk(
  "reportsData/NextDebitTxnFileReports",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/getNextDebitTxnFile`,
      requestParam
    );
    return response.data;
  }
);
// viewSelected Slice----

const reportsDataSlice = createSlice({
  name: "userManagement",
  initialState: reportsData,
  reducers: {
    changeTheMandateTableView: (reportsData, action) => {
      reportsData.uiState.allMandatesViewMaterialtable.tableShouldBeShown =
        action.payload;
    },
    changeTheCancelMandateTableView: (reportsData, action) => {
      reportsData.uiState.allCancelMandatesViewMaterialtable.tableShouldBeShown =
        action.payload;
    },
    changeTheDebitTableView: (reportsData, action) => {
      reportsData.uiState.allDebitsViewMaterialtable.tableShouldBeShown =
        action.payload;
    },
    changeTheDebitTxnView: (reportsData, action) => {
      reportsData.uiState.allDebitsTXNMaterialtable.tableShouldBeShown =
        action.payload;
    },
    changeTheNextDebitTxnView: (reportsData, action) => {
      reportsData.uiState.allNextDebitTxnNMaterialtable.tableShouldBeShown =
        action.payload;
    },

    clearAllMandateList: (reportsData, action) => {
      reportsData.allMandateList = [];
    },
    clearAllMandateListFiltered: (reportsData, action) => {
      reportsData.allMandateListFiltered = { records: [], count: 0 };
    },
    clearAllDebitListFiltered: (reportsData, action) => {
      reportsData.allDebitListFiltered = { records: [], count: 0 };
    },
    clearAllDebitList: (reportsData, action) => {
      reportsData.allDebitList = [];
    },
    clearDebitTxnFileReports: (reportsData, action) => {
      reportsData.DebitTxnFileReports = [];
    },
    clearNextDebitTxnFileReports: (reportsData, action) => {
      reportsData.NextDebitTxnFileReports = [];
    },
    clearAllPendindMandateListFiltered: (reportsData, action) => {
      reportsData.allPendindMandateListFiltered = [];
    },
    clearAllPendingMandateList: (reportsData, action) => {
      reportsData.allPendingMandateList = [];
    },
    clearAllCancelMandateListFiltered: (reportsData, action) => {
      reportsData.allCancelMandateListFiltered = [];
    },
    clearAllCancelMandateList: (reportsData, action) => {
      reportsData.allCancelMandateList = [];
    },
  },
  extraReducers: {
    [fetchAllMandateListData.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [fetchAllMandateListData.fulfilled]: (reportsData, action) => {
      reportsData.allMandateList = action.payload;
    },
    [fetchAllMandateListData.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },

    [fetchAllPendingMandateListData.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [fetchAllPendingMandateListData.fulfilled]: (reportsData, action) => {
      reportsData.allPendingMandateList = action.payload;
    },
    [fetchAllPendingMandateListData.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },

    [fetchAllCancelMandateListData.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [fetchAllCancelMandateListData.fulfilled]: (reportsData, action) => {
      reportsData.allCancelMandateList = action.payload;
    },
    [fetchAllCancelMandateListData.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },

    [fetchAllDebitListData.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [fetchAllDebitListData.fulfilled]: (reportsData, action) => {
      reportsData.allDebitList = action.payload;
    },
    [fetchAllDebitListData.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },

    ///For Mandate Filter Data with Filters---
    [filterMandateReportWithFilters.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [filterMandateReportWithFilters.fulfilled]: (reportsData, action) => {
      reportsData.allMandateListFiltered = action.payload;
    },
    [filterMandateReportWithFilters.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },

    ///For Pending Mandate Filter Data with Filters---
    [filterPendingMandateReportWithFilters.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [filterPendingMandateReportWithFilters.fulfilled]: (
      reportsData,
      action
    ) => {
      reportsData.allPendindMandateListFiltered = action.payload;
    },
    [filterPendingMandateReportWithFilters.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },

    ///For Cancel Mandate Filter Data with Filters---
    [filterCancelMandateReportWithFilters.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [filterCancelMandateReportWithFilters.fulfilled]: (reportsData, action) => {
      reportsData.allCancelMandateListFiltered = action.payload;
    },
    [filterCancelMandateReportWithFilters.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },

    ///////For Debit Filter Data with Filters --------
    [filterDebitReportWithFilters.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [filterDebitReportWithFilters.fulfilled]: (reportsData, action) => {
      reportsData.allDebitListFiltered = action.payload;
    },
    [filterDebitReportWithFilters.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },

    // updateRegistrationCancellation
    [updateRegistrationCancellation.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [updateRegistrationCancellation.fulfilled]: (reportsData, action) => {},
    [updateRegistrationCancellation.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },

    //approveCancelMandate
    [approveCancelMandate.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [approveCancelMandate.fulfilled]: (reportsData, action) => {},
    [approveCancelMandate.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },
    /// DebitTxnFileReports
    [DebitTxnFileReports.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [DebitTxnFileReports.fulfilled]: (reportsData, action) => {
      reportsData.DebitTxnFileReports = JSON.parse(
        JSON.stringify(action.payload)
      );
    },
    [DebitTxnFileReports.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },
    /// NextDebitTxnFileReports
    [NextDebitTxnFileReports.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [NextDebitTxnFileReports.fulfilled]: (reportsData, action) => {
      reportsData.NextDebitTxnFileReports = JSON.parse(
        JSON.stringify(action.payload)
      );
    },
    [NextDebitTxnFileReports.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },
    ////////getPhyMandateXL
    [getPhyMandateXL.pending]: (reportsData, action) => {
      reportsData.status = "pending";
    },
    [getPhyMandateXL.fulfilled]: (reportsData, action) => {
      reportsData.getPhyMandateXLResponse = action.payload;
    },
    [getPhyMandateXL.rejected]: (reportsData, action) => {
      reportsData.status = "failed";
      reportsData.error = action.error.message;
    },
  },
});

// Exporting uiState actions
export const {
  changeTheMandateTableView,
  changeTheDebitTxnView,
  changeTheNextDebitTxnView,
  changeTheCancelMandateTableView,
  changeTheDebitTableView,
  clearAllMandateList,
  clearAllMandateListFiltered,
  clearAllDebitListFiltered,
  clearAllDebitList,
  clearDebitTxnFileReports,
  clearNextDebitTxnFileReports,
  clearAllPendindMandateListFiltered,
  clearAllPendingMandateList,
  clearAllCancelMandateList,
  clearAllCancelMandateListFiltered,
} = reportsDataSlice.actions;

// Exporting slice reducer
export default reportsDataSlice.reducer;

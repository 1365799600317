import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../helper/Formik/FormControl/FormControl";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { viewSelected } from "../../store/selectedView";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTransactionsEnquiry,
  TransactionsEnquiryData,
} from "../../store/TransactionsEnquiry";
import { clearmandateEnquiry, mandateEnquiryData } from "../../store/mandateEnquiry";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: "fit-content",
    minWidth: "82vw",
    maxWidth: "82vw",
  },
  buttonStyle: {
    padding: "20px",
  },
}));

const columns = [
  { title: "Mandate Registration ID", field: "mandateRegistrationId" },
  { title: "Mandate Max Amount", field: "mandateMaxAmount" },
  { title: "Mandate Purpose", field: "mandatePurpose" }, 
  { title: "Authentication Mode", field: "authenticationMode" },
  { title: "Mandate Type", field: "mandateType" },
  { title: "Payer Name", field: "payerName" },
  { title: "Payer Email", field: "payerEmail" },
  { title: "Payer Mobile", field: "payerMobile" },
  { title: "Client Code", field: "clientCode" },
  { title: "Regestration Status", field: "regestrationStatus" },
  { title: "UMRN Number", field: "umrnNumber" },
  { title: "Consumer Reference Number", field: "consumerReferenceNumber" },
  { title: "Mandate Registration Time", field: "mandateRegTime" },
  { title: "Mandate Start Date", field: "mandateStartDate" },
  { title: "Mandate Credit Time", field: "mandateCreditTime" },

];

function MandateEnquiry(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("MandateEnquiry");
  const listItem = useSelector(
    (state) => state.entities.mandateEnquirySlice.mandateEnquiryData
  );
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const initialValues = {
    MandateId: "",
  };
  const validationSchema = Yup.object({});
  const onSubmit = (values) => {
    setLoading(true);
    dispatch(mandateEnquiryData(values.MandateId))
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    //   effect
    return () => {
      dispatch(clearmandateEnquiry());
    };
  }, []);
  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static">
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Mandate Enquiry" value="MandateEnquiry" />
          </TabList>
        </AppBar>
        <TabPanel value="MandateEnquiry">
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form>
                    <Grid container>
                      <Paper></Paper>
                      <Grid item md={4} xs={12}>
                        <FormikControl
                          control="input"
                          label="Mandate ID"
                          name="MandateId"
                          onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^[0-9]*$/;
                            if (!value || regex.test(value.toString())) {
                              formik.setFieldValue("MandateId", value);
                            }
                          }}
                          type="tel"
                          min="0"
                          maxlength="18"
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        justifyContent="center"
                        className={classes.buttonStyle}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Search{" "}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                {" "}
                <List>
                  <ListItem className={classes.listHeader}>
                    <ListItemText primary=" " />
                  </ListItem>
                </List>
                <List>
                  <ListItem className={classes.nested}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      item={true}
                    >
                      {columns.map((item, index) => {
                        if (item.field==="mandateCreditTime"||item.field==="mandateRegTime"||item.field==="mandateStartDate"){
                          let DateTime=listItem[`${item.field}`]
                          return (
                            <Grid item xs={12} sm={4} key={index}>
                              {console.log(listItem[`${item.field}`])}
                              {listItem[`${item.field}`] === "" ||
                              listItem[`${item.field}`] === undefined ||
                              listItem[`${item.field}`] === "not found" ? (
                                <></>
                              ) : (
                                <><ListItemText
                                  primary={item.title}
                                  secondary={ <ListItemText
                                    primary={`${DateTime.dayOfMonth}  ${DateTime.month} ${DateTime.year}`}
                                    secondary={`${DateTime.hour}:${DateTime.minute}:${DateTime.second}`}
                                  />}
                                />
                               </>
                              )}
                            </Grid>
                          )
                        }
                        else{
                        return (
                          <Grid item xs={12} sm={4} key={index}>
                            {console.log(listItem[`${item.field}`])}
                            {listItem[`${item.field}`] === "" ||
                            listItem[`${item.field}`] === undefined ||
                            listItem[`${item.field}`] === "not found" ? (
                              <></>
                            ) : (
                              <ListItemText
                                primary={item.title}
                                secondary={listItem[`${item.field}`]}
                              />
                            )}
                          </Grid>
                        );}
                      })}
                    </Grid>
                  </ListItem>
                </List>
              </>
            )}
          </>
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default MandateEnquiry;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";

const merchantManagement = {
  createMerchantFormData: {
    p_merchantcode: "",
    p_merchantname: "",
    p_contactperson: "",
    p_contactdesignation: "",
    p_address: "",
    p_isactive: "1",
    p_contactnumber: "",
    p_registrationFee: 0,
    p_transactionFee: 0,
    p_Return_bounceFee: 0,
    p_chargbackFee: 0,
    p_physicalregistrationFee: 0,
    p_physicaltransactionFee: "0",
    p_physicalReturnbounceFee: "0",
  },

  listOfMerchants: [],

  rolesDropdownList: [],
  merchantsDropdownList: [],

  error: "",
  status: "",
};

export const createNewMerchant = createAsyncThunk(
  "merchantManagement/createNewMerchant",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/SaveUpdateMerchant`,
      requestParam
    );
    return response.data;
  }
);

export const editExistingMerchant = createAsyncThunk(
  "merchantManagement/editExistingMerchant",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/SaveUpdateMerchant`,
      requestParam
    );
    return response.data;
  }
);

export const fetchMerchantList = createAsyncThunk(
  "merchantManagement/fetchMerchantList",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/SaveUpdateMerchant`,
      {
        p_type: "S",
        p_merchantid: requestParam.merchant_id,
        p_merchantcode: "NA",
        p_merchantname: "NA",
        p_contactperson: "NA",
        p_contactdesignation: "NA",
        p_address: "NA",
        p_isactive: "1",
        p_createdby: "NA",
        p_contactnumber: "NA",
      }
    );
    return response.data;
  }
);

export const fetchRolesDropDownList = createAsyncThunk(
  "merchantManagement/fetchRolesDropDownList",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/getRole`
    );
    return response.data;
  }
);

export const fetchMerchantsDropDownList = createAsyncThunk(
  "merchantManagement/fetchMerchantsDropDownList",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/getMerchant`
    );
    return response.data;
  }
);

const merchantManagementSlice = createSlice({
  name: "merchantManagement",
  initialState: merchantManagement,
  reducers: {},
  extraReducers: {
    //------Create New Merchant-------------------
    [createNewMerchant.pending]: (merchantManagement, action) => {
      merchantManagement.status = "pending";
    },
    [createNewMerchant.fulfilled]: (merchantManagement, action) => {},
    [createNewMerchant.rejected]: (merchantManagement, action) => {
      merchantManagement.status = "failed";
      merchantManagement.error = action.error.message;
    },

    //------Edit Existing Merchant-------------------
    [editExistingMerchant.pending]: (merchantManagement, action) => {
      merchantManagement.status = "pending";
    },
    [editExistingMerchant.fulfilled]: (merchantManagement, action) => {},
    [editExistingMerchant.rejected]: (merchantManagement, action) => {
      merchantManagement.status = "failed";
      merchantManagement.error = action.error.message;
    },

    //-------Fetch Roles dropdown------------------
    [fetchRolesDropDownList.pending]: (merchantManagement, action) => {
      merchantManagement.status = "pending";
    },
    [fetchRolesDropDownList.fulfilled]: (merchantManagement, action) => {
      merchantManagement.rolesDropdownList = action.payload;
    },
    [fetchRolesDropDownList.rejected]: (merchantManagement, action) => {
      merchantManagement.status = "failed";
      merchantManagement.error = action.error.message;
    },

    //-------Fetch Merchants dropdown------------------
    [fetchMerchantsDropDownList.pending]: (merchantManagement, action) => {
      merchantManagement.status = "pending";
    },
    [fetchMerchantsDropDownList.fulfilled]: (merchantManagement, action) => {
      merchantManagement.merchantsDropdownList = action.payload;
    },
    [fetchMerchantsDropDownList.rejected]: (merchantManagement, action) => {
      merchantManagement.status = "failed";
      merchantManagement.error = action.error.message;
    },

    //-------Fetch List of Merchants------------------
    [fetchMerchantList.pending]: (merchantManagement, action) => {
      merchantManagement.status = "pending";
    },
    [fetchMerchantList.fulfilled]: (merchantManagement, action) => {
      merchantManagement.listOfMerchants = JSON.parse(
        JSON.stringify(action.payload)
      );
    },
    [fetchMerchantList.rejected]: (merchantManagement, action) => {
      merchantManagement.status = "failed";
      merchantManagement.error = action.error.message;
    },
  },
});

//exporting actions
export const {} = merchantManagementSlice.actions;

//exporting slice reducer
export default merchantManagementSlice.reducer;

//-------------------------------- This Component controls the navigation and view display on different routes-------------
import React, { Fragment, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import RouteWrapper from "../hoc/RouteWrapper";

import MainLayout from "../layout/MainLayout";
import BlankLayout from "../layout/BlankLayout";

import { useSelector } from "react-redux";
import { withRouter } from "react-browser-router";
import { isObjectEmpty } from "../utilities/isObjectEmpty";
import ProtectedRouteWrapper from "../hoc/ProtectedRoute";
import { getCookies } from "../utilities/getCookies";
import MandateRegistrationStatusView from "../view/emandateOnline/components/MandateRegistrationStatus/MandateRegistrationStatusView";
import MandateComplete from "../view/MandateComplete/MandateComplete";
import Fallback from "./Fallbacks/Fallback";
import SettelmentsReports from "../view/SettelmentsTab/SettelmentsReport/SettelmentsReports";
import TransactionEnquiry from "../view/TransactionEnquiryTab/TransactionEnquiry";
import MandateEnquiry from "../view/mandateEnquiry/MandateEnquiry";
const CreateBulkMandate = lazy(() => import("../view/BulkOperations/createBulkMandate/CreateBulkMandate"));

//---------------------------------------------Code Spliting for---Performance Boost------------------------------------------
const MisReport = lazy(() => import("../view/Reports/MisReport/MisReport"));
const InvoiceReports = lazy(() =>
  import("../view/Reports/InvoiceReports/InvoiceReports")
);
const OnlineEmandateView = lazy(() =>
  import("../view/emandateOnline/OnlineEmandateView")
);
const LoginPageView = lazy(() => import("../view/loginPage/loginPageView"));
const SettelmentsView = lazy(() =>
  import("../view/SettelmentsTab/SettelmentsView")
);
const EmandateView = lazy(() => import("../view/Emandate/EmandateView"));
const CancelMandate = lazy(() => import("../view/CancelMandate/CancelMandate"));
// const CancelMandate = lazy(() => import("../"));

const BulkOperations = lazy(() =>
  import("../view/BulkOperations/BulkOperationsView")
);
const UploadFileView = lazy(() => import("../view/UploadFile/UploadFileView"));
const UpdateResponseView = lazy(() =>
  import("../view/UpdateResponse/UpdateResponseview1")
);

const UserManagementView = lazy(() =>
  import("../view/UserManagement/UserManagementView")
);
const MerchantManagementView = lazy(() =>
  import("../view/MerchantManagement/MerchantManagementView")
);
const AllMandatesReportView = lazy(() =>
  import("../view/Reports/AllRegisteredMandateReports/AllMandatesReportView")
);
const AllCancelMandateReportView = lazy(() =>
  import("../view/Reports/CancelandateReports/AllCancelMandateReportView")
);

const CancelMandateForm = lazy(() =>
  import("../view/CancelMandate/components/cancelMandateForm/CancelMandateForm")
);
const RegisteredPhysicalMandateView = lazy(() =>
  import(
    "../view/emandateOffline/components/RegisteredPhysicalMandate/RegisteredPhysicalMandateView"
  )
);
const UpdateResponseFileView = lazy(() =>
  import(
    "../view/UpdateResponse/components/UpdateResponseFileView/UpdateResponseFileView"
  )
);
const AdHocMandateView = lazy(() =>
  import("../view/AdHocMandate/AdHocMandateViewNew")
);
const AllPendingMandateReportsView = lazy(() =>
  import("../view/Reports/PendingReports/AllPendingMandateReportsView")
);
const MandatePaymentViaSabpaisaView = lazy(() =>
  import("../view/MandatePaymentViaSabpaisa/MandatePaymentViaSabpaisaView")
);

const AllDebitReports = lazy(() =>
  import("../view/Reports/DebitReports/AllDebitReports")
);
const DebitRequests = lazy(() => import("../view/DebitRequests/DebitRequests"));
//--------------------------------------------------------------------------------------------------------------------------------

function AllRoutes(props) {
  const currentUser = useSelector((state) => state.auth.currentUser);
  return (
    <Fragment>
      <Suspense
        fallback={
          <div>
            <Fallback />
          </div>
        }
      >

        <Switch>
          <Route
            exact
            path="/login"
            render={(props) => {
              if (isObjectEmpty(currentUser)) {
                if (getCookies().currentUser === undefined) {
                  return <LoginPageView />;
                } else {
                  props.history.push("/");
                }
              } else {
                props.history.push("/");
              }
            }}
          />

          {/* ---------------------/home and "/" displays the same view--------------- */}
          <ProtectedRouteWrapper
            exact
            path="/home"
            layout={MainLayout}
            component={EmandateView}
          />
          <ProtectedRouteWrapper
            exact
            path="/"
            layout={MainLayout}
            component={EmandateView}
          />
          {/* ------------------------------------------------------------------------ */}
          {/*---------------:mandateRegistrationId slug is needed to invoke the AdHocMandateView Page  ------*/}
          <Route
            exact
            path="/mandateLink/:mandateRegistrationId"
            render={(props) => {
              return <AdHocMandateView />;
            }}
          />
          {/* This is for redirection to home page if  mandateRegistrationId slug is not present  */}
          <Redirect exact from="/mandateRegistration" to="/" />
          {/* -------------------------------------------------------------------------------------------------- */}
          {/* This is online mandate registration route where mandteReqId is generated by generateUniqueId utility  */}
          <ProtectedRouteWrapper
            exact
            path="/mandateRegistration/:mandateReqId"
            layout={MainLayout}
            component={OnlineEmandateView}
          />
          <ProtectedRouteWrapper
            exact
            path="/create-bulk-mandate"
            layout={MainLayout}
            component={CreateBulkMandate}
          />
          {/* -------------------------------------------------------------------------------------------------- */}
          {/* This is the route to handle mandate registration status  */}
          <ProtectedRouteWrapper
            exact
            path="/mandateRegistrationStatus"
            layout={MainLayout}
            component={MandateRegistrationStatusView}
          />
          {/* -------------------------------------------------------------------------------------------------- */}
          {/* This is the route to handle mandate registration status for AdHoc Case----this will be open and not protected */}
          <RouteWrapper
            exact
            path="/mandateLinkRegistrationStatus"
            layout={BlankLayout}
            component={MandateRegistrationStatusView}
          />

          <RouteWrapper
            exact
            path="/mandateLinkRegistrationComplete"
            layout={BlankLayout}
            component={MandateComplete}
          />

          {/* -------------------------------------------------------------------------------------------------- */}
          {/* This is the route conist component for registered physical mandate component*/}
          <Route
            exact
            path="/registeredPhysicalMandate"
            render={(props) => {
              return <RegisteredPhysicalMandateView />;
            }}
          />
          {/* -------------------------------------------------------------------------------------------------- */}
          {/* <Route
          exact
          path="/mandateRegistrationStatus"
          // layout={MainLayout}
          component={MandateRegistrationStatusView}
        /> */}

          {/* This is the route for Viewing the List of cancelled Mandated and create new mandate cancellation request */}
          <ProtectedRouteWrapper
            exact
            path="/cancelMandate"
            layout={MainLayout}
            component={CancelMandate}
          />
          <ProtectedRouteWrapper
            exact
            path="/settelments"
            layout={MainLayout}
            component={SettelmentsView}
          />
          {/* This is the route for client to view the mandate cancellation form */}
          <Route
            exact
            path="/cancelMandateForm/:merchantId"
            render={(props) => {
              return <CancelMandateForm />;
            }}
          />

          {/* This is is the route for Uploading files manually ----flow not confirmed yet-- 3rd March 2021 */}
          {currentUser.filter_id === 0 ? (
            <ProtectedRouteWrapper
              exact
              path="/uploadFile"
              layout={MainLayout}
              component={UploadFileView}
            />
          ) : null}
          {/* -------------------------------------------------------------------------------------------------- */}
          {/* This is is the route for finding Updated mandate list   ----flow not confirmed yet-- 3rd March 2021 */}

          {currentUser.filter_id === 0 ? (
            <ProtectedRouteWrapper
              exact
              path="/updateResponse"
              layout={MainLayout}
              component={UpdateResponseView}
            />
          ) : null}
          {/* -------------------------------------------------------------------------------------------------- */}

          {/* This is is the route for viewing Updated mandate excel   ----flow not confirmed yet-- 3rd March 2021 */}
          {currentUser.filter_id === 0 ? (
            <ProtectedRouteWrapper
              exact
              path="/updateResponse/:uploadFileName"
              layout={MainLayout}
              component={UpdateResponseFileView}
            />
          ) : null}

          {/* -------------------------------------------------------------------------------------------------- */}

          {/* This consist user management view */}
          <ProtectedRouteWrapper
            exact
            path="/userManagement"
            layout={MainLayout}
            component={UserManagementView}
          />
          {/* -------------------------------------------------------------------------------------------------- */}

          {/* This consist merchant management view */}
          {currentUser.filter_id === 0 ? (
            <ProtectedRouteWrapper
              exact
              path="/merchantManagement"
              layout={MainLayout}
              component={MerchantManagementView}
            />
          ) : null}

          {/* ------------------------------------------------------------------------------------------------------- */}

          {/* This consist mBulk Operations View */}
          <ProtectedRouteWrapper
            exact
            path="/bulkOperations"
            layout={MainLayout}
            component={BulkOperations}
          />

          {/* -------------------------------------------------------------------------------------------------- */}
          {/* <ProtectedRouteWrapper
          exact
          path="/reports"
          layout={MainLayout}
          component={ReportsView}
        /> */}
          {/* This route is being used  to view mandate Reports */}
          <ProtectedRouteWrapper
            exact
            path="/reports/mandateReports"
            layout={MainLayout}
            component={AllMandatesReportView}
          />
          {/* -------------------------------------------------------------------------------------------------- */}
          {/* This route is being used  to view debit Reports */}
          <ProtectedRouteWrapper
            exact
            path="/reports/debitReports"
            layout={MainLayout}
            component={AllDebitReports}
          />
          <ProtectedRouteWrapper
            exact
            path="/reports/SettelmentsReports"
            layout={MainLayout}
            component={SettelmentsReports}
          />

          <ProtectedRouteWrapper
            exact
            path="/PaymentDetailSummary"
            layout={MainLayout}
            component={InvoiceReports}
          />
          <ProtectedRouteWrapper
            exact
            path="/MisReport"
            layout={MainLayout}
            component={MisReport}
          />
          {/* -------------------------------------------------------------------------------------------------- */}
          {/* This route is being used  to view pendingMandateReports*/}

          {/* {currentUser.filter_id===0?
            <ProtectedRouteWrapper
              exact
              path="/reports/pendingMandateReports"
              layout={MainLayout}
              component={AllPendingMandateReportsView}
            />
          :
            null
          } */}

          <ProtectedRouteWrapper
            exact
            path="/reports/pendingMandateReports"
            layout={MainLayout}
            component={AllPendingMandateReportsView}
          />
          {/* -------------------------------------------------------------------------------------------------- */}
          {/* This route is being used  to view cancelMandateReports*/}
          <ProtectedRouteWrapper
            exact
            path="/reports/cancelMandateReports"
            layout={MainLayout}
            component={AllCancelMandateReportView}
          />
          <ProtectedRouteWrapper
            exact
            path="/DebitRequests"
            layout={MainLayout}
            component={DebitRequests}
          />
          <ProtectedRouteWrapper
            exact
            path="/TransactionEnquiry"
            layout={MainLayout}
            component={TransactionEnquiry}
          />
          <ProtectedRouteWrapper
            exact
            path="/MandateEnquiry"
            layout={MainLayout}
            component={MandateEnquiry}
          />


          {/* -------------------------------------------------------------------------------------------------- */}
          {/* -------------------------------------------------------------------------------------------------- */}
          <Route
            exact
            path="/mandatePayment"
            // layout={MainLayout}
            component={MandatePaymentViaSabpaisaView}
          // component={TestForFormik}
          />
          {/* ---------------------------------------------------------------------------------------------------- */}
          {/* This is a dummy route not to be included in production ----only used for testing any component  */}

          {/* <Route exact path="/test" component={AdHocMandateViewNew} /> */}

          {/* -------------------------------------------------------------------------------------------------- */}
          {/* ---------------------------------------------------------------------------------------------------- */}
          {/* <RouteWrapper
          exact
          path="/emandate"
          layout={MainLayout}
          component={OnlineEmandateView}
        /> */}
          {/* <RouteWrapper
          exact
          path="/emandate/:currentView"
          layout={MainLayout}
          component={PayoutRequest}
        /> */}
          {/* <RouteWrapper
          exact
          path="/emandate/payoutRequest/:id/:requestType"
          layout={MainLayout}
          component={PayoutRequest}
        /> */}
          <Redirect from="/" to="/" />
        </Switch>
      </Suspense>
    </Fragment>
  );
}
export default withRouter(AllRoutes);

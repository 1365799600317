//This reducer is the final reducer the we configure store with ----entities ,aut,uiState
import { combineReducers } from "redux";
import entitiesReducers from "./entities";
import authReducers from "./auth";
import uiState from "./uiState";

export default combineReducers({
  entities: entitiesReducers,
  auth: authReducers,
  uiState: uiState,
});

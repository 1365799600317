import React from "react";
import { makeStyles } from "@material-ui/core/";
import InputField from "../components/PhysicalMandateInputField";
import SignatureField from "../components/PhysicalMandateSignatureField";
import IndividualCharBoxTextField from "../../../../../helper/ReusableComponents/IndividualCharBoxFields/IndividualCharBoxTextField";
import IndividualCharBoxDateField from "../../../../../helper/ReusableComponents/IndividualCharBoxFields/IndividualCharBoxDateField";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    border: "2px solid black",
    width: "899 px",
    // height:"450px",
    // width: "fit-content",
    height: "fit-content",
    // maxWidth: "1100px",
    flexDirection: "column",
    padding: "5px 20px",
  },
  top: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  topLeft: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  mandateHeader: {
    padding: "0px",
    color: "black",
    fontWeight: "bold",
    fontSize: "small",
    marginBottom: "1px",
    width: "fit-content",
  },
  topRight: {
    display: "flex",
    flexDirection: "column",
    // alignItems:'flex-end'
  },
  fieldRow: {
    display: "flex",
    // height: "30px",
    margin: "3px",
    // marginBottom:'5px',
    width: "fit-content",
  },
  fieldRowSignature: {
    display: "flex",
    height: "30px",
    // height:'fit-content',
    marginBottom: "20px",
    justifyContent: "space-between",
  },
  logo: {
    width: "120px",
    height: "70px",
  },
  label: {
    fontSize: "small",
    marginBottom: "5px",
  },
  input: {
    display: "flex",
  },
  middle: {
    display: "flex",
    flexDirection: "column",
  },
  bottom: {
    display: "flex",
    marginTop: "10px",
  },
  period: {
    display: "flex",
    border: "1px solid black",
    // width: "fit-content",
    width: "230px",
    flexDirection: "column",
    padding: "1px",
    marginRight: "20px",
  },
  bottomRight: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    display: "flex",
    // height: "10px",
    // height:'fit-content',
    marginBottom: "0px",
  },
  checkboxLabel: {
    marginRight: "10px",
    fontSize: "small",
  },
  disclaimerText: {
    fontSize: "small",
    padding: "0px",
  },
  disclaimerList: {
    padding: "15px",
    margin: "0px",
  },
  mandateTypeItem: {
    border: "1px solid black",
    width: "75px",
    marginBottom: "4px",
  },
}));

export default function PhysicalMandateForm(props) {
  const classes = useStyles();

  const frequencyTypeMapping = {
    Adhoc: ["Adhoc", "ADHO"],
    "Bi-Mthly": ["Bi-Monthly", "BIMN"],
    Daily: ["Daily", "DAIL"],
    Intra: ["Intra", "INDA"],
    Mthly: ["Monthly", "MNTH"],
    Qtly: ["Quaterly", "QURT"],
    Recc: ["Reoccurring", "RCUR"],
    Semi: ["Semi", "MIAN"],
    Seq: ["Sequence", "OOFF"],
    Wkly: ["Weekly", "WEEK"],
    Yrly: ["Yearly", "YEAR"],
  };

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.topLeft}>
          <div className={classes.logo}></div>
          <div>
            <div className={classes.mandateTypeItem}>
              <span className={classes.checkboxLabel}>Create</span>
              <input
                type="checkbox"
                name="mandate-type"
                value="Create"
                readonly
                checked={true}
              />
            </div>
            <div className={classes.mandateTypeItem}>
              <span className={classes.checkboxLabel}>Modify</span>
              <input
                type="checkbox"
                name="mandate-type"
                value="Modify"
                readonly
                checked={false}
              />
            </div>
            <div className={classes.mandateTypeItem}>
              <span className={classes.checkboxLabel}>Cancel</span>
              <input
                type="checkbox"
                name="mandate-type"
                value="Cancel"
                readonly
                checked={false}
              />
            </div>
          </div>
        </div>
        <div className={classes.topRight}>
          <div className={classes.mandateHeader}>
            Mandate Registration Form for NACH
          </div>

          <div className={classes.fieldRow}>
            <IndividualCharBoxTextField
              label="UMRN"
              value={props.formData.umrnNumber ? props.formData.umrnNumber : ""}
              max_length="21"
            />
            <IndividualCharBoxDateField
              label="Date"
              value={props.formData.creationDate}
            />
          </div>
          <div className={classes.fieldRow}>
            <InputField
              label="Sponsor Bank's Code"
              value={props.formData.bankName}
              width="240px"
              withLabel={true}
            />
            <InputField
              label="Utility Code"
              value="NACH00000000022341"
              withLabel={true}
              width="255px"
            />
          </div>
          <div className={classes.fieldRow}>
            <InputField
              label="I/We hearby authorize"
              value={props.formData.userName.maidenName}
              withLabel={true}
              width="300px"
            />
          </div>
          <div className={classes.fieldRow}>
            <IndividualCharBoxTextField
              label="Bank Account No"
              value={props.formData.accountNumber}
              max_length="29"
            />
          </div>
        </div>
      </div>
      <div className={classes.middle}>
        <div className={classes.fieldRow}>
          <InputField
            label="With Bank"
            value={props.formData.fullBankName}
            withLabel={true}
            width="170px"
          />

          <IndividualCharBoxTextField
            label="IFSC"
            value={props.formData.ifscCode}
            max_length="11"
          />
          <IndividualCharBoxTextField
            label="or MICR"
            value=""
            max_length="10"
          />
        </div>
        <div className={classes.fieldRow}>
          <InputField
            label="an Amount of Rupees"
            value={props.formData.mandateMaxAmount}
            withLabel={true}
            width="510px"
          />
        </div>

        <div className={classes.fieldRow}>
          <div style={{ width: "450px", marginRight: "70px" }}>
            <span className={classes.checkboxLabel}>Frequency:</span>

            {Object.keys(frequencyTypeMapping).map((key) => (
              <Fragment>
                <input
                  type="checkbox"
                  name="frequency-type"
                  value={key}
                  readonly
                  checked={
                    frequencyTypeMapping[key].includes(props.formData.frequency)
                      ? true
                      : false
                  }
                />
                <span className={classes.checkboxLabel}>{key}</span>
              </Fragment>
            ))}
          </div>
          <div style={{ width: "350px" }}>
            <span className={classes.checkboxLabel}>Debit Type:</span>
            <input
              className={classes.checkboxLabel}
              type="checkbox"
              name="debit-type"
              value="Fixed-Amount"
              checked
              readonly
            />
            <span className={classes.checkboxLabel}>Fixed Amount</span>
            <input
              className={classes.checkboxLabel}
              type="checkbox"
              name="debit-type"
              value="Maximum-Amount"
              readonly
              checked={false}
            />
            <span className={classes.checkboxLabel}>Maximum Amount</span>
          </div>
        </div>

        <div className={classes.fieldRow}>
          <InputField
            label="Schema Ref No"
            value={props.formData.consumerReferenceNumber}
            withLabel={true}
            width="300px"
          />
          <InputField
            label="Phone Number"
            value={`+${props.formData.mobileNumber.countryCode}-${props.formData.mobileNumber.number}`}
            withLabel={true}
            width="320px"
          />
        </div>
        <div className={classes.fieldRow}>
          <InputField
            label="Client Ref No"
            value={props.formData.schemeReferenceNumber}
            withLabel={true}
            width="315px"
          />
          <InputField
            label="Email"
            value={props.formData.payerEmail}
            withLabel={true}
            width="375px"
          />
        </div>
        <div className={classes.text}>
          <div className={classes.disclaimerText}>
            I agree for the debit of mandate processing charges by the bank
            whome I am authorising to debit my account as per latest schedule of
            charges of the bank.
          </div>
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.period}>
          <label className={classes.label}>Period</label>
          <IndividualCharBoxDateField
            label="From"
            value={props.formData.startDate}
          />

          <IndividualCharBoxDateField
            label="To"
            value={props.formData.endDate}
          />

          <div>
            <input
              className={classes.checkboxLabel}
              type="checkbox"
              name="until-cancelled"
              value={props.formData.untilCancelled}
              checked={props.formData.untilCancelled}
              readonly
            />
            <span className={classes.checkboxLabel}>Until Cancelled</span>
          </div>
        </div>
        <div className={classes.bottomRight}>
          <div className={classes.fieldRowSignature}>
            <SignatureField label="Signature of 1st Holder" />
            <SignatureField label="Signature of 2nd Account Holder" />
            <SignatureField label="Signature of 3rd Account Holder" />
          </div>
          <div className={classes.fieldRowSignature}>
            <SignatureField label="Name as per Bank Account" />
            <SignatureField label="Name as per Bank Account" />
            <SignatureField label="Name as per Bank Account" />
          </div>
        </div>
      </div>
      <ul className={classes.disclaimerList}>
        <li>
          <span className={classes.text}>
            <div className={classes.disclaimerText}>
              This is to confirm that the declaration has been carefully
              read,understood & made by me/us. I am authorizing the user
              entity/corporate to debit my a/c.
            </div>
          </span>
        </li>
        <li>
          <span className={classes.text}>
            <div className={classes.disclaimerText}>
              I have understood that I am authorized to cancel/amend this
              mandate by appropriately communicating the cancellation/amendment
              request to the User entity/corporate or the bank where I have
              authorized the debit.
            </div>
          </span>
        </li>
      </ul>
    </div>
  );
}

import { useState } from "react";

const useMuiFormField = (initialValue, type) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const fieldType = type;
  const handleChange = (newValue) => {
    if (newValue === "") {
      setError(true);
      setHelperText("This is a Required field !");
      setValue(newValue);
    } else {
      setError(false);
      setHelperText("");
      setValue(newValue);
    }
  };
  const handleBlur = (newValue) => {
    if (newValue === "") {
      setError(true);
      setHelperText("This is a Required field !");
      setValue(newValue);
    } else {
      setError(false);
      setHelperText("");
      setValue(newValue);
    }
  };
  return {
    type: fieldType,
    value: value,
    setValue: setValue,
    error: error,
    setError: setError,
    helperText: helperText,
    setHelperText: setHelperText,
    handleChange: handleChange,
    handleBlur: handleBlur,
  };
};

export default useMuiFormField;

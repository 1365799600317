import React from "react";
import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    marginRight: "25px",
  },
  label: {
    // margin: "10px"
    marginRight: "10px",
    fontSize: "small",
  },
  input: {
    background: "white",
  },
}));

export default function PhysicalMandateInputField(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {props.withLabel ? (
        <lable className={classes.label}>{props.label}</lable>
      ) : null}
      <input
        style={{
          background: "white",
          width: props.width,
        }}
        disabled
        value={props.value}
      />
    </div>
  );
}

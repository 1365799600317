import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

var utc = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
const fileExtension = ".xlsx";

export const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { [fileName]: ws }, SheetNames: [fileName] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + utc + fileExtension);
};

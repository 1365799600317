import { makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
}));

export default function DatePickerField({
  labelName,
  stateHandler,
  idProps,
  ...rest
}) {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Typography>{labelName}</Typography>
      <KeyboardDatePicker
        className="input__field"
        style={{ display: "flex" }}
        id={idProps}
        InputProps={{ readOnly: true }}
        selected={stateHandler.value}
        value={stateHandler.value}
        onChange={(e) =>
          stateHandler.handleChange(new Date(e).toISOString().slice(0, 10))
        }
        minDateMessage={""}
        {...rest}
        format="MM/dd/yyyy"
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
      <Typography color="secondary">
        {stateHandler.error ? stateHandler.helperText : ""}
      </Typography>
    </MuiPickersUtilsProvider>
  );
}

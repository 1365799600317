import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";
import { generateUniqueId } from "../utilities/generateUniqueId";
import listDateToString from "../utilities/listDateToString";

const Invoice = {
  merchantDropdownData: [],
  InvoiceData: [],
  status: "",
  error: "",
};

export const getAllMerchants = createAsyncThunk(
  "invoice/getAllMerchants",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/getMerchant`
    );
    return response.data;
  }
);
export const getFilteredDataForInvoice = createAsyncThunk(
  "invoice/getConsolidatedReport",
  async (reqParams) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/getConsolidatedReport`,
      {
        p_merchant_id: `${reqParams.merchantSelect.value}`,
        p_from_date: reqParams.startDate.value,
        p_to_date: reqParams.endDate.value,
      }
    );
    return response.data;
  }
);

// viewSelected Slice----

const invoivceSlice = createSlice({
  name: "invoice",
  initialState: Invoice,
  reducers: {
    InvoiceDataList: (Invoice, action) => {
      const data = Invoice.InvoiceData;
      return data;
    },
    clearInvoiceData: (Invoice, action) => {
      Invoice.InvoiceData = [];
    },
  },
  extraReducers: {
    [getAllMerchants.pending]: (state, action) => {
      state.status = "pending";
    },
    [getAllMerchants.fulfilled]: (state, action) => {
      state.merchantDropdownData = action.payload;
    },
    [getAllMerchants.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getFilteredDataForInvoice.pending]: (state, action) => {
      state.status = "pending";
    },
    [getFilteredDataForInvoice.fulfilled]: (state, action) => {
      state.InvoiceData = JSON.parse(JSON.stringify(action.payload));
    },
    [getFilteredDataForInvoice.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

// Exporting onlineEmandate actions
export const { InvoiceDataList, clearInvoiceData } = invoivceSlice.actions;

// Exporting slice reducer
export default invoivceSlice.reducer;

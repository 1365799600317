import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SabpaisaLogo from "../../../assets/images/sp-logo.svg";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CurrentUserProfile from "../CurrentUserProfile/CurrentUserProfile";
import { useDispatch } from "react-redux";
import { menuIconForSmallScreenSideDrawerOpenClicked } from "../../../store/selectedView";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
  },
  appBarRightText: {
    color: "black",
  },

  toolBar: {
    display: "flex",
    direction: "row",
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(-1),
    [theme.breakpoints.up("960")]: {
      display: "none",
    },
  },
  LogoutButton: {
    color: "black",
  },
}));

const Header = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClickMenuButtonForSwipableDrawerSmallScreen = () => {
    dispatch(menuIconForSmallScreenSideDrawerOpenClicked());
  };

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={1}>
      <Toolbar className={classes.toolBar}>
        <div>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={onClickMenuButtonForSwipableDrawerSmallScreen}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img alt="Sabpaisa Logo" src={SabpaisaLogo} height="" width="160" />
        </div>

        <CurrentUserProfile />
      </Toolbar>
    </AppBar>
  );
});
export default Header;

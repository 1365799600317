import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../TextError/TextError";
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function DatePicker(props) {
  const { name, label, onChange, disabled, ...rest } = props;
  return (
    <div className="form__control">
      <label htmlFor={name}>{label}</label>
      {/* <div className="form__control__field__div"> */}
      <Field name={name}>
        {({ field, form }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            // <DateView
            //   className="input__field"
            //   id={name}
            //   {...field}
            //   {...rest}
            //   selected={value}
            //   onChange={(val) => setFieldValue(name, val)}
            // />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="input__field"
                style={{ display: "flex" }}
                id={name}
                InputProps={{ readOnly: true }}
                {...field}
                {...rest}
                selected={value}
                value={value}
                allowKeyboardControl={false}
                onChange={
                  onChange ? onChange : (val) => setFieldValue(name, val)
                }
                format="MM/dd/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
      {/* </div> */}
    </div>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";

const userManagement = {
  createUserFormData: {
    p_firstname: "",
    p_lastname: "",
    p_username: "",
    p_password: "",
    p_confirmpassword: "",
    p_roleid: "NA",
    p_merchantid: "NA",
  },

  listOfUsers: [],

  rolesDropdownList: [],
  merchantsDropdownList: [],
  error: "",
  status: "",
};

export const creatNewUser = createAsyncThunk(
  "userManagement/creatNewUser",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/SaveUpdateUser`,
      requestParam
    );
    return response.data;
  }
);

export const editExistingUser = createAsyncThunk(
  "userManagement/editExistingUser",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/SaveUpdateUser`,
      requestParam
    );
    return response.data;
  }
);

export const fetchRolesDropDownList = createAsyncThunk(
  "userManagement/fetchRolesDropDownList",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/getRole`
    );
    return response.data;
  }
);

export const fetchMerchantsDropDownList = createAsyncThunk(
  "userManagement/fetchMerchantsDropDownList",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/getMerchant`
    );
    return response.data;
  }
);

export const fetchUserList = createAsyncThunk(
  "userManagement/fetchUserList",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/SaveUpdateUser`,
      {
        p_type: "S",
        p_uid: "0",
        p_firstname: "NA",
        p_lastnam: "NA",
        p_username: "NA",
        p_password: "NA",
        p_roleid: "0",
        p_merchantid: requestParam.merchant_id,
        p_loginby: "NA",
      }
    );
    return response.data;
  }
);

// viewSelected Slice----

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: userManagement,
  reducers: {
    resetCreateFormData: (store) => {
      store.createUserFormData = {
        p_firstname: "",
        p_lastname: "",
        p_username: "",
        p_password: "",
        p_confirmpassword: "",
        p_roleid: "NA",
        p_merchantid: "NA",
      };
    },
  },
  extraReducers: {
    //------Create New User-------------------
    [creatNewUser.pending]: (userManagement, action) => {
      userManagement.status = "pending";
    },
    [creatNewUser.fulfilled]: (userManagement, action) => {},
    [creatNewUser.rejected]: (userManagement, action) => {
      userManagement.status = "failed";
      userManagement.error = action.error.message;
    },

    //------Edit Existing User-------------------
    [editExistingUser.pending]: (userManagement, action) => {
      userManagement.status = "pending";
    },
    [editExistingUser.fulfilled]: (userManagement, action) => {},
    [editExistingUser.rejected]: (userManagement, action) => {
      userManagement.status = "failed";
      userManagement.error = action.error.message;
    },

    //-------Fetch Roles dropdown------------------
    [fetchRolesDropDownList.pending]: (userManagement, action) => {
      userManagement.status = "pending";
    },
    [fetchRolesDropDownList.fulfilled]: (userManagement, action) => {
      userManagement.rolesDropdownList = action.payload;
    },
    [fetchRolesDropDownList.rejected]: (userManagement, action) => {
      userManagement.status = "failed";
      userManagement.error = action.error.message;
    },

    //-------Fetch Merchants dropdown------------------
    [fetchMerchantsDropDownList.pending]: (userManagement, action) => {
      userManagement.status = "pending";
    },
    [fetchMerchantsDropDownList.fulfilled]: (userManagement, action) => {
      userManagement.merchantsDropdownList = action.payload;
    },
    [fetchMerchantsDropDownList.rejected]: (userManagement, action) => {
      userManagement.status = "failed";
      userManagement.error = action.error.message;
    },

    //-------Fetch List of Users------------------
    [fetchUserList.pending]: (userManagement, action) => {
      userManagement.status = "pending";
    },
    [fetchUserList.fulfilled]: (userManagement, action) => {
      userManagement.listOfUsers = action.payload;
    },
    [fetchUserList.rejected]: (userManagement, action) => {
      userManagement.status = "failed";
      userManagement.error = action.error.message;
    },
  },
});

// Exporting userManagement actions
export const { resetCreateFormData } = userManagementSlice.actions;

// Exporting slice reducer
export default userManagementSlice.reducer;

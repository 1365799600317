import React from "react";
import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
container:{
    display:'flex',
    alignItems:'center',
    marginRight:'40px'
    },
  label:{
    marginRight:'10px',
    fontSize:'small'
  },
  letter: {
    width: "15px",
    border: "1px solid black",
    textAlign: "center"
  },
}));

export default function IndividualCharBoxes(props) {
  const classes = useStyles();
  const text = props.value.toUpperCase();

  let x = []
  for(let i=0;i<props.max_length;i++){
     x.push(
      <input
        type="text"
        name="letter1"
        className={classes.letter}
        maxLength="1"
        key={i}
        value={text[i]}
        disabled
      />
     )
  }

  return (
    <div className={classes.container}>
      <label className={classes.label}>{props.label}</label>
      {x}
    </div>
  );
}

import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../TextError/TextError";
import "../formStyle.css";

export default function Input(props) {
  const { label, name, stringType, pattern, ...rest } = props;
  return (
    <div className="form__control">
      <label htmlFor={name}>{label}</label>
      {/* <div className="form__control__field__div"> */}
      {stringType === "number" ? (
        <Field
          render={({
            field,
            form,
            meta /* { name, value, onChange, onBlur } */,
          }) => {
            return (
              <input
                name={name}
                id={name}
                {...rest}
                className="input__field"
                value={field.value[name]}
                onChange={
                  pattern
                    ? (e) => {
                        var val = e.target.value.trim();
                        return isNaN(val)
                          ? null
                          : form.setFieldValue(name, val);
                      }
                    : (e) =>
                        isNaN(e.target.value)
                          ? null
                          : form.setFieldValue(name, e.target.value)
                }
              />
            );
          }}
        />
      ) : (
        <Field className="input__field" name={name} id={name} {...rest} />
      )}

      <ErrorMessage name={name} component={TextError} />
      {/* </div> */}
    </div>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";

const bulkOptions = {
  bulkPhysicalMandateDownloadList: [],
  status: "idle",
  error: "",
  fromDate: new Date().toISOString().slice(0, 10),
  toDate: new Date().toISOString().slice(0, 10),
  pendingEMandateList: [],
  physicalMandateList: { records: [], count: 0 },
  mandateCategoryDropDownJSON: {},
  mandateFrequencyJSON: {},
  mandateTypeJSON: {},
  allBanksJSON: {},
};

export const fetchPendingEMandateList = createAsyncThunk(
  "bulkOptions/fetchPendingEMandateList",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterRegistration`,
      {
        fromDate: bulkOptions.fromDate,
        toDate: bulkOptions.toDate,
        m_id: requestParam.merchant_id,
        page: 1,
        length: 10000,
      }
    );
    return response.data.filter((item) => item.bankName === null);
  }
);

export const fetchPhysicalMandateList = createAsyncThunk(
  "bulkOptions/fetchPhysicalMandateList",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterRegistration`,
      {
        fromDate: bulkOptions.fromDate,
        toDate: bulkOptions.toDate,
        m_id: requestParam.merchant_id,
        page: 1,
        length: 10000,
      }
    );
    return response.data.records.filter(
      (item) =>
        // item.regestrationStatus === "INITIATED" &&
        item.mandateType === "PHYSICAL"
    );
  }
);

export const filteredPhysicalMandateList = createAsyncThunk(
  "bulkOptions/filteredPhysicalMandateList",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterMandateReport`,
      requestParam
    );
    return response.data.records.filter(
      (item) =>
        item.regestrationStatus === "INITIATED" &&
        item.mandateType === "PHYSICAL"
    );
  }
);

export const fetchMandateCategoryDropdown = createAsyncThunk(
  "bulkOptions/fetchMandateCategoryDropdown",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/MandateCategory`
    );
    return response.data;
  }
);

export const fetchMandateFrequencyJSON = createAsyncThunk(
  "bulkOptions/fetchMandateFrequencyJSON",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/frequency`
    );
    return response.data;
  }
);

export const fetchMandateTypeJSON = createAsyncThunk(
  "bulkOptions/fetchMandateTypeJSON",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/MandateType`
    );
    return response.data;
  }
);

export const fetchAllBanksJSON = createAsyncThunk(
  "bulkOptions/fetchAllBanksJSON",
  async () => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetCommonData/0/allbank`
    );
    return response.data;
  }
);
export const UploadMandateDocs = createAsyncThunk(
  "bulkOptions/UploadMandateDocs",
  async (reqParams) => {
    const response = await Axios.post(
      `${config.backendUrl}/uploadPhysicalMandate`,
      reqParams
    );
    return response.data;
  }
);
export const CreateMandateInBulk = createAsyncThunk(
  "bulkOptions/CreateMandateInBulk",
  async (reqParams) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/createMandateInBulk`,
      reqParams
    );
    return response.data;
  }
);

const bulkOptionsSlice = createSlice({
  name: "bulkOptions",
  initialState: bulkOptions,
  reducers: {
    addPhysicalMandateRecordCreated: (bulkOptions, action) => {
      bulkOptions.bulkPhysicalMandateDownloadList = [
        ...bulkOptions.bulkPhysicalMandateDownloadList,
        action.payload.physicalMandateRecordCreated,
      ];
    },
  },

  extraReducers: {
    //fetchPendingEMandateList
    [fetchPendingEMandateList.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchPendingEMandateList.fulfilled]: (state, action) => {
      state.pendingEMandateList = action.payload;
    },
    [fetchPendingEMandateList.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //fetchPhysicalMandateList
    [fetchPhysicalMandateList.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchPhysicalMandateList.fulfilled]: (state, action) => {
      state.physicalMandateList = action.payload;
    },
    [fetchPhysicalMandateList.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //filteredPhysicalMandateList
    [filteredPhysicalMandateList.pending]: (state, action) => {
      state.status = "pending";
    },
    [filteredPhysicalMandateList.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.physicalMandateList = action.payload;
    },
    [filteredPhysicalMandateList.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    // fetchMandateCategoryDropdown--

    [fetchMandateCategoryDropdown.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchMandateCategoryDropdown.fulfilled]: (state, action) => {
      const jsonTransform = {};
      action.payload.map(
        (item) => (jsonTransform[item.code] = item.description)
      );

      state.mandateCategoryDropDownJSON = jsonTransform;
    },
    [fetchMandateCategoryDropdown.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //fetchMandateFrequencyJSON--
    [fetchMandateFrequencyJSON.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchMandateFrequencyJSON.fulfilled]: (state, action) => {
      const jsonTransform = {};
      action.payload.map(
        (item) => (jsonTransform[item.code] = item.description)
      );

      state.mandateFrequencyJSON = jsonTransform;
    },
    [fetchMandateFrequencyJSON.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //fetchMandateTypeJSON--
    [fetchMandateTypeJSON.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchMandateTypeJSON.fulfilled]: (state, action) => {
      const jsonTransform = {};
      action.payload.map(
        (item) => (jsonTransform[item.code] = item.description)
      );

      state.mandateTypeJSON = jsonTransform;
    },
    [fetchMandateTypeJSON.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //fetchAllBanksJSON--
    [fetchAllBanksJSON.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchAllBanksJSON.fulfilled]: (state, action) => {
      const jsonTransform = {};
      action.payload.map(
        (item) => (jsonTransform[item.code] = item.description)
      );

      state.allBanksJSON = jsonTransform;
    },
    [fetchAllBanksJSON.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    ////////////UploadMandate/////////////////////////
    [UploadMandateDocs.pending]: (state, action) => {
      state.status = "pending";
    },
    [UploadMandateDocs.fulfilled]: (state, action) => {
      state.status = "fullfilled";
    },
    [UploadMandateDocs.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    ////////////Create MandateinBulk/////////////////////////
    [CreateMandateInBulk.pending]: (state, action) => {
      state.status = "pending";
    },
    [CreateMandateInBulk.fulfilled]: (state, action) => {
      state.status = "fullfilled";
    },
    [CreateMandateInBulk.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

// Exporting onlineEmandate actions
export const { addPhysicalMandateRecordCreated } = bulkOptionsSlice.actions;

// Exporting slice reducer
export default bulkOptionsSlice.reducer;

import React, { useRef } from "react";
import PhysicalMandateForm from "./components/PhysicalMandateForm";
import { Button, Grid, makeStyles } from "@material-ui/core/";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReactToPdf from "react-to-pdf";
import { jsPDF } from "jspdf";
// import ReactDOMServer, { renderToString } from "react-dom/server";
import { download } from "../../../../utilities/downloadPhysicalMandate";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "block",
    margin: "0 150px 0 125px",
    // alignItems: "center",
    // flexDirection: "column",
    //  marginRight:"250px"
    // overflow:'auto'
  },
  qrCode: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
  },
  page: {
    width: "75%",
  },
  PrintableForm: {
    // marginRight:'50px'
    margin: "5px 5px 0 3px",
    // // size: "landscape",
    // size:" 60mm 50mm",
    // display:'flex',
    // flexDirection:'column',
    // alignItems:'center',
    // overflow:'auto'
  },
  printIcon: {
    fontSize: "50px",
    marginLeft: "25px",
  },
}));

function PhysicalMandate(props) {
  const classes = useStyles();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const downloadOptions = {
    orientation: "landscape",
    unit: "in",
    // format: [4, 2]
  };
  // download(props.formData);

  return (
    <div className={classes.container}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button varient="primary" onClick={handlePrint}>
          <PrintIcon className={classes.printIcon} />
          PRINT
        </Button>
        {/* <Pdf /> */}
        <ReactToPdf
          targetRef={componentRef}
          filename={`PhysicalMandate(${props.formData.creationDate}).pdf`}
          options={downloadOptions}
          x={0.5}
          y={0.5}
          scale={0.8}
        >
          {({ toPdf }) =>
            props.directDownload ? (
              toPdf()
            ) : (
              <Button varient="primary" onClick={toPdf}>
                <GetAppIcon className={classes.printIcon} />
                Download
              </Button>
            )
          }
        </ReactToPdf>
      </div>
      <div ref={componentRef}>
        <Grid xs={1}> </Grid>
        <Grid
          xs={10}
          style={{
            paddingLeft: "3px",
            paddingTop: "30px",
            paddingRight: "5px",
          }}
        >
          <div className={classes.PrintableForm}>
            {/* <h4>NACH/ECS/Auto Debit Mandate Instruction Form</h4> */}
            <PhysicalMandateForm formData={props.formData} />
            <div className={classes.qrCode}>{props.children}</div>
          </div>
        </Grid>
        <Grid xs={1}> </Grid>
      </div>
    </div>
  );
}
export default PhysicalMandate;

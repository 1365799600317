import { InputLabel, MenuItem, NativeSelect } from "@material-ui/core";
import React, { Fragment } from "react";

export default function NativeSelectField(props) {
  return (
    <Fragment>
      <InputLabel shrink id={props.labelID}>
        {props.labelName}
      </InputLabel>

      <NativeSelect
        helperText={props.stateHandler.helperText}
        fullWidth
        name={props.name}
        placeholder={props.placeholder}
        labelId={props.labelID}
        id={props.selectFieldId}
        value={props.stateHandler.value}
        displayEmpty
        onChange={(e) => {
          props.stateHandler.handleChange(e.target.value);
        }}
        error={props.stateHandler.error}
      >
        <option value=""> Select </option>
        {props.menuItemsList.map((menuItem) => (
          <option value={menuItem.code}> {menuItem.description} </option>
        ))}
      </NativeSelect>
    </Fragment>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";

const mandateEnquiry = {
    mandateEnquiryData: {mandateRegistrationId:"",
    clientCode:"",
    clientRegistrationId:"",
    consumerReferenceNumber:"",
    mandatePurpose:"",
    payerUtilitityCode:"",
    payerName:"",
    mandateEndDate:"",
    mandateMaxAmount:"",
    mandateRegTime:"not found",
    mandateType:"",
    merchantId:"",
    mandateStartDate:"not found",
    messageId:"",
    mandateCollectAmount:"",
    panNo:"",
    mandateCategoryCode:"",
    npciPaymentBankCode:"",
    payerAccountNumber:"",
    payerAccountType:"",
    payerBank:"",
    payerEmail:"",
    payerMobile:"",
    telePhone:"",
    payerBankIfscCode:"",
    authenticationMode:"",
    frequency:"",
    requestType:"",
    schemeReferenceNumber:"",
    sponserBank:"",
    regestrationStatus:"",
    totalAmount:"",
    umrnNumber:"",
    untilCancelled:"",
    mandateCreditTime:"not found",
    mandateupdatedon:"",
    mandateupdatedby:"",
    regestrationErrorCode:"",
    regestrationErrorDesc:"",
    regestrationNpciRefId:"",
    accptDetails_CreDtTm:"",
    bankName:"",
    mandatecategory:"",
    mandatPhysicalPath:"",
    isphymndtupdate:"",
    isphymndtupdatedon:"",
    isphymndtupdatedby:"",
    isphymndtapprove:"",
    isphymndtapproveon:"",
    isphymndtapproveby:"",
    userType:"",
    mandateImage:"",
    emiamount:"",
    mandateCancelled:"",
    reqInitPty:"",
    accptd:"",
    reasonCode:"",
    reasonDesc:"",
    rejectBy:""}};

// viewSelected Slice----
export const mandateEnquiryData = createAsyncThunk(
  "Settlement/mandateEnquiryData",
  async (requestParam) => {
    const data = new FormData();
    data.append("mandateId", requestParam);
    const response = await Axios.post(
      `${config.backendUrl}/REST/getMandateById`,
      data
    );

    return response.data;
  }
);
const mandateEnquirySlice = createSlice({
  name: "mandateEnquiry",
  initialState: mandateEnquiry,
  reducers: {
    clearmandateEnquiry: (state, action) => {
      state.mandateEnquiryData={mandateRegistrationId:"",
      clientCode:"",
      clientRegistrationId:"",
      consumerReferenceNumber:"",
      mandatePurpose:"",
      payerUtilitityCode:"",
      payerName:"",
      mandateEndDate:"",
      mandateMaxAmount:"",
      mandateRegTime:"not found",
      mandateType:"",
      merchantId:"",
      mandateStartDate:"not found",
      messageId:"",
      mandateCollectAmount:"",
      panNo:"",
      mandateCategoryCode:"",
      npciPaymentBankCode:"",
      payerAccountNumber:"",
      payerAccountType:"",
      payerBank:"",
      payerEmail:"",
      payerMobile:"",
      telePhone:"",
      payerBankIfscCode:"",
      authenticationMode:"",
      frequency:"",
      requestType:"",
      schemeReferenceNumber:"",
      sponserBank:"",
      regestrationStatus:"",
      totalAmount:"",
      umrnNumber:"",
      untilCancelled:"",
      mandateCreditTime:"not found",
       mandateupdatedon:"",
      mandateupdatedby:"",
      regestrationErrorCode:"",
      regestrationErrorDesc:"",
      regestrationNpciRefId:"",
      accptDetails_CreDtTm:"",
      bankName:"",
      mandatecategory:"",
      mandatPhysicalPath:"",
      isphymndtupdate:"",
      isphymndtupdatedon:"",
      isphymndtupdatedby:"",
      isphymndtapprove:"",
      isphymndtapproveon:"",
      isphymndtapproveby:"",
      userType:"",
      mandateImage:"",
      emiamount:"",
      mandateCancelled:"",
      reqInitPty:"",
      accptd:"",
      reasonCode:"",
      reasonDesc:"",
      rejectBy:""}
    },
  },

  extraReducers: {
    [mandateEnquiryData.pending]: (state, action) => {
      state.status = "pending";
    },
    [mandateEnquiryData.fulfilled]: (state, action) => {
      state.mandateEnquiryData= action.payload;
    },
    [mandateEnquiryData.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { clearmandateEnquiry } = mandateEnquirySlice.actions;
// Exporting slice reducer
export default mandateEnquirySlice.reducer;

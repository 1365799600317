const dev = {
  environment: "dev",
  bankInvokePath: "/",
  // backendUrl: "https://subscriptionapi.sabpaisa.in/subscription",
  backendUrl: "https://subscription.sabpaisa.in/subscription",
  // backendUrl: "http://192.168.34.61:8081/subscription",

  // proxy: "https://thingproxy.freeboard.io/fetch/",
  proxy: "",
  uploadFileUrl: "https://test-mandate-upload.web.app",

  // proxy: "https://cors-anywhere.herokuapp.com/",
  // proxy: "",
};
export default dev;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  layout_wrapper: {
    display: "flex",
    // overflow: "scroll",
  },
  content: {
    flexGrow: 1,
    // padding: "18px 0 0 220px",
    padding: theme.spacing(3),
    overflow: "auto",
  },
}));

const BlankLayout = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.layout_wrapper}>
      <main className={classes.content}>
        {props.children}
      </main>
    </div>
  );
};

export default BlankLayout;

import toastConfig from "./TostNotifications/tostTypes";

export default function listTransform(mainList, attList) {
  try {
    // throw "ayush"
    if (!mainList) {
      return [];
    }

    let formattedList = [];
    formattedList = mainList.map((listItem) => {
      var itemKeyList = Object.keys(listItem);
      const data = { ...listItem };
      itemKeyList.forEach((item) => {
        if (
          attList.includes(item) &&
          listItem[item] !== null &&
          listItem[item]
        ) {
          let oldDate = data[item];

          // let x = new Date(`${oldDate[0]}-${oldDate[1]}-${oldDate[2]}`);
          let x = `${oldDate[2]}-${oldDate[1]}-${oldDate[0]}`;

          // let updatedDate = x.toDateString();
          // let updatedDate = x.toISOString().slice(0,10);

          data[item] = x;
        } else {
        }
      });
      return data;
    });
    return formattedList;
  } catch (err) {
    // toastConfig.errorToast("Please Contact Your Administrator.");
  }
}

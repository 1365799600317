import { SwipeableDrawer } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { menuIconForSmallScreenSideDrawerOpenClicked } from "../../../store/selectedView";
import SideDrawerItems from "./components/SideDrawerItems";

export default function SideDrawerForSmallScreen() {
  const dispatch = useDispatch();
  var menuIconForSmallScreenSideDrawerOpen = useSelector(
    (state) => state.entities.selectedView.menuIconForSmallScreenSideDrawerOpen
  );
  return (
    <SwipeableDrawer
      anchor="left"
      open={menuIconForSmallScreenSideDrawerOpen}
      onClose={() => dispatch(menuIconForSmallScreenSideDrawerOpenClicked())}
      onOpen={() => dispatch(menuIconForSmallScreenSideDrawerOpenClicked())}
    >
      <SideDrawerItems />
    </SwipeableDrawer>
  );
}

// import axios from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import QRCodeGenerator from "../../../../helper/ReusableComponents/QRCodeGenerator";
import { download } from "../../../../utilities/downloadPhysicalMandate";
import listDateToString from "../../../../utilities/listDateToString";
import DialogWrapper from "../../../Emandate/Mandate/components/DialogWrapper/DialogWrapper";
import PhysicalMandate from "../PhysicalMandate/PhysicalMandate";

export default function RegisteredPhysicalMandateView(props) {
  const toShow = true;
  useEffect(() => {
    return () => {};
  }, []);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const formData = {
    bankName: props.apiData.npciPaymentBankCode,
    fullBankName: props.apiData.bankName,

    userName: {
      maidenName: props.apiData.payerName,
    },

    accountNumber: props.apiData.payerAccountNumber,
    ifscCode: props.apiData.payerBankIfscCode,

    mandateMaxAmount: props.apiData.mandateMaxAmount,
    emiamount: props.apiData.emiamount,
    mobileNumber: {
      countryCode: props.apiData.payerMobile.split("-")[0].replace("+", ""),
      number: props.apiData.payerMobile.split("-")[1],
    },
    startDate: listDateToString(props.apiData.mandateStartDate),
    endDate: props.apiData.mandateEndDate
      ? listDateToString(props.apiData.mandateEndDate)
      : null,

    umrnNumber: props.apiData.umrnNumber,
    creationDate: listDateToString(props.apiData.mandateCreditTime),
    utilityCode: props.apiData.payerUtilitityCode,
    payerBank: props.apiData.payerBank,
    frequency: props.apiData.frequency,

    payerEmail: props.apiData.payerEmail,

    schemeReferenceNumber: props.apiData.schemeReferenceNumber,
    consumerReferenceNumber: props.apiData.consumerReferenceNumber,
    untilCancelled: props.apiData.untilCancelled,
  };

  return (
    <div>
      {toShow && (
        <DialogWrapper
          title="Physical Mandate"
          open={props.toShowPhysicalMandateForm}
          handleClose={() => {
            props.setToShowPhysicalMandateForm(
              !props.toShowPhysicalMandateForm
            );
          }}
          fullWidth={true}
        >
          {/* {"-------------Utkarsh Form here-----"} */}

          <PhysicalMandate
            formData={formData}
            directDownload={props.directDownload}
          >
            {currentUser.is_qrcode ? (
              <QRCodeGenerator
                valueToEncode={props.apiData.mandateRegistrationId}
              />
            ) : (
              <></>
            )}
          </PhysicalMandate>

          {/* <MandateDetailsContent listItem={props.listItem} /> */}
        </DialogWrapper>
      )}
    </div>
  );
}

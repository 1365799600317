export default function listDateToString(listDateArg) {
  if (!listDateArg) {
    return "";
  }
  let listDate = listDateArg;

  if (typeof listDateArg == "string") {
    listDate = listDateArg.split("-");
  }
  var newDate = listDate.map((value) => {
    return parseInt(value) / 10 < 1
      ? (parseInt(value) / 100).toString().split(".")[1]
      : parseInt(value);
  });
  return `${newDate[0]}-${newDate[1]}-${newDate[2]}`;
}

//----------------This slice is used for managing user authentication at forntend---------------
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";
import delete_cookie from "../utilities/deleteCookie";

const auth = {
  //currentUser stores the data about current user----to be replaced by jwt token after it's implementation in backend
  currentUser: {},
  status: "",
  error: "",
};
// Axios.defaults.headers.common['Cache-Control'] = "only-if-cached";

// Async thunk for handling user login ---------------
export const userLoggedIn = createAsyncThunk(
  "auth/userLoggedIn",
  async (requestParam) => {
    // const response = await Axios.post(
    //   `${config.backendUrl}/REST/checkLogin`,
    //   requestParam
    // );
    const response = await Axios.post(
      `${config.backendUrl}/REST/checkLogin`,
      // `https://sso.sabpaisa.in/sso/admin/login/admin`,
      // `http://192.168.34.49:8081/sso/admin/login/admin`,
      requestParam
    );
    return response.data;
  }
);
// export const userLoggedIn1 = createAsyncThunk(
//   "auth/userLoggedIn",
//   async (requestParam) => {
//     const response = await Axios.post(
//       `${config.backendUrl}REST/authenticate`,

//       requestParam
//     );
//     return response.data;
//   }
// );

// Async thunk for handling password change---------------
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/changePassword`,
      requestParam
    );
    return response.data;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: auth,
  reducers: {
    // This reducer is called at the initial load of application -----this sets current admin user ----hardcoded as of now ----
    adminLoggedIn: (auth, action) => {
      document.cookie =
        `currentUser={"user_id":3,"user_first_name":"Admin","user_last_name":"Admin","user_name":"Admin","password":"*****","role_id":"1","designation_id":"1","created_on":"2021-01-22 14:52:55","modified_on":"2021-01-22 14:52:55"};` +
        `expires=` +
        new Date(9999, 0, 1).toUTCString();
    },

    // this reducer is called after logging out of the application ----this delets the current user in the cookies
    userLoggedOut: (auth, action) => {
      delete_cookie("currentUser");
      auth.currentUser = {};
    },

    // this reducer is called for setting currentUser object  in the slice with the current user fetched after log in
    setCurrentUserFromCookies: (auth, action) => {
      auth.currentUser = action.payload.currentUser;
    },
  },
  extraReducers: {
    [userLoggedIn.pending]: (state, action) => {
      state.status = "pending";
    },
    [userLoggedIn.fulfilled]: (state, action) => {
      // alert("inside reducer");
      if (action.payload.length !== 0) {
        // document.cookie =
        //   `currentUser=${JSON.stringify(action.payload[0])};` +
        //   `expires=` +
        //   new Date(9999, 0, 1).toUTCString();
        document.cookie =
          `currentUser=${JSON.stringify(action.payload[0])};` +
          `expires=` +
          new Date(9999, 0, 1).toUTCString();
      }
      state.currentUser = action.payload[0];
    },
    [userLoggedIn.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    // this Reducer is for Checking Password
    // [userLoggedIn1.pending]: (state, action) => {
    //   state.status = "pending";
    // },
    // [userLoggedIn1.fulfilled]: (state, action) => {
    // },
    // [userLoggedIn1.rejected]: (state, action) => {
    //   state.status = "failed";
    //   state.error = action.error.message;
    // },
    // this Reducer is for Updating Password
    [changePassword.pending]: (state, action) => {
      state.status = "pending";
    },
    [changePassword.fulfilled]: (state, action) => {
      state.status = "fulfilled";
    },
    [changePassword.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

// Exporting onlineEmandate actions
export const { userLoggedOut, setCurrentUserFromCookies, adminLoggedIn } =
  authSlice.actions;

export default authSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";

const penaltyTokenStore = {
  tokenResultBasedonUmrnNumber: "",
  tokenFormDetails: {
    p_base_amount: "",
    p_token_amount: "",
    p_created_by: "",
    p_valid_to: null,
  },
  penaltyTokenList: [],
  status: "idle",
  error: "",
};

export const fetchTokenDetailsFromUMRNumber = createAsyncThunk(
  "penaltyTokenStore/fetchTokenDetailsFromUMRNumber",
  async (requestParam) => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/GetScalarValues/1/${requestParam.umrNumber}`
    );
    return response.data;
  }
);

export const insertPenaltyToken = createAsyncThunk(
  "penaltyTokenStore/insertPenaltyToken",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/SaveUpdateToken`,
      requestParam
    );
    return response.data;
  }
);

export const fetchPenaltyTokenList = createAsyncThunk(
  "penaltyTokenStore/fetchPenaltyTokenList",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/SaveUpdateToken`,
      {
        p_type: "S",
        p_id: "0",
        p_umrn: "NA",
        p_base_amount: "0",
        p_valid_to: "NA",
        p_mid: requestParam.p_mid,
        p_created_by: "NA",
        p_token_amount: "0",
      }
    );
    return response.data;
  }
);

export const updatePenaltyToken = createAsyncThunk(
  "penaltyTokenStore/updatePenaltyToken",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/SaveUpdateToken`,
      requestParam
    );
    return response.data;
  }
);

const penaltyTokenSlice = createSlice({
  name: "penaltyTokenStore",
  initialState: penaltyTokenStore,
  reducers: {
    resetTokenResult: (penaltyTokenStore, action) => {
      penaltyTokenStore.tokenResultBasedonUmrnNumber = "";
    },
  },
  extraReducers: {
    // fetchTokenDetailsFromUMRNumber
    [fetchTokenDetailsFromUMRNumber.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchTokenDetailsFromUMRNumber.fulfilled]: (state, action) => {
      state.tokenResultBasedonUmrnNumber = action.payload["result"];
    },
    [fetchTokenDetailsFromUMRNumber.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //insertPenaltyToken
    [insertPenaltyToken.pending]: (state, action) => {
      state.status = "pending";
    },
    [insertPenaltyToken.fulfilled]: (state, action) => {
      // console.log("Token Inserted");
    },
    [insertPenaltyToken.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //fetchPenaltyTokenList
    [fetchPenaltyTokenList.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchPenaltyTokenList.fulfilled]: (state, action) => {
      state.penaltyTokenList = action.payload;
    },
    [fetchPenaltyTokenList.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //updatePenaltyToken
    [updatePenaltyToken.pending]: (state, action) => {
      state.status = "pending";
    },
    [updatePenaltyToken.fulfilled]: (state, action) => {
      // console.log("Token updated");
    },
    [updatePenaltyToken.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

// Exporting penaltyTokenSlice actions
export const { resetTokenResult } = penaltyTokenSlice.actions;

// Exporting slice reducer
export default penaltyTokenSlice.reducer;

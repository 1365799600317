import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";
const bulkOptions = {
  Progressdata: "",
};

export const downloadPhysicalMandateByMandateId = createAsyncThunk(
  "downloadPhysicalMandateByMandateId/singleDownload",
  async (reqParam) => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/downloadMandate/${reqParam}`
    );
    return `${config.backendUrl}/REST/downloadMandate/${reqParam}`;
  }
);
export const downloadPhysicalMandateInBulk = createAsyncThunk(
  "downloadPhysicalMandateByMandateId/BulkDownload",
  async (reqParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/zipDownload`,

      reqParam,
      {
        // onDownloadProgress: function (progressEvent) {
        //   var percentCompleted = Math.round(
        //     (progressEvent.loaded * 100) / progressEvent.total
        //   );
        // },
        responseType: "blob",
      }
    );

    return response.data;
  }
);

const downloadPhysicalMandateSlice = createSlice({
  name: "downloadPhysicalMandateByMandateId",
  initialState: bulkOptions,
  reducers: {},

  extraReducers: {
    ////////////Single Physical Mandate /////////////////////////
    [downloadPhysicalMandateByMandateId.pending]: (state, action) => {
      state.status = "pending";
    },
    [downloadPhysicalMandateByMandateId.fulfilled]: (state, action) => {
      state.status = "fullfilled";
    },
    [downloadPhysicalMandateByMandateId.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    ////////////BULK Physical Mandate /////////////////////////

    [downloadPhysicalMandateInBulk.pending]: (state, action) => {
      state.status = "pending";
      state.Progressdata = "Downloading";
    },
    [downloadPhysicalMandateInBulk.fulfilled]: (state, action) => {
      state.status = "fullfilled";
      state.Progressdata = "";
    },
    [downloadPhysicalMandateInBulk.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.Progressdata = "";
    },
  },
});

export default downloadPhysicalMandateSlice.reducer;

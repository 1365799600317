import React from "react";
import { Button, makeStyles, Typography, Grid } from "@material-ui/core";
import FormikControl from "../../../../../helper/Formik/FormControl/FormControl";

import { changePassword } from "../../../../../store/auth";

// Importing redux toolkit hooks--
import { useDispatch, useSelector } from "react-redux";

// Importing Formik and Yup for form creation and validation--
import { Formik, Form } from "formik";
import * as Yup from "yup";
import toastConfig from "../../../../../utilities/TostNotifications/tostTypes";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function ChangePasswordForm(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("This is a required field")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const onSubmit = (values) => {
    let request = {
      uid: props.user_id,
      newPassword: values.newPassword,
    };

    dispatch(changePassword(request))
      .then((res) => {
        if (res.payload.result === "True") {
          toastConfig.successToast("Your Password has been updated!");
          props.handleCloseChangePasswordDialog();
        }
      })
      .catch((err) => {
        toastConfig.errorToast("Something went wrong!");
      });
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container>
              <Grid item md={12} xs={12}>
                <FormikControl
                  control="input"
                  label="New Password"
                  name="newPassword"
                  type="password"
                  min="0"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormikControl
                  control="input"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  min="0"
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button type="submit" variant="contained" color="primary">
                Update Password
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePasswordForm;

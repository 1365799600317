import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { withRouter } from "react-browser-router";
import queryString from "query-string";
import Link from "@material-ui/core/Link";
import { generateUniqueId } from "../../../../../utilities/generateUniqueId";
import listTransform from "../../../../../utilities/listTransform";
import DisabledTextField from "./DisabledTextField";

import CancelLogo from "../../../../../assets/images/cancel.png";

const useStyles = makeStyles((theme) => ({
  root: {},
  failedIcon: {
    color: "red",
  },
}));

function MandateRegistrationFailed(props) {
  const classes = useStyles();
  const dataList = [];
  dataList.push(props.apiData);

  const dataTransform = listTransform(dataList, [
    "mandateEndDate",
    "mandateStartDate",
    "mandateRegTime",
    "mandateCreditTime",
  ])[0];

  const dataToShowInStatus = [
    "mandateRegistrationId",
    "clientRegistrationId",
    "regestrationStatus",
  ];
  const dataToShowInStatusMapping = {
    mandateRegistrationId: "Mandate Registration ID",
    clientRegistrationId: "Client Registration ID",
    regestrationStatus: "Status",
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
      spacing={1}
    >
      <Grid item xs={12}>
        <img src={CancelLogo}></img>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid
        item
        xs={10}
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.root}
      >
        {Object.keys(dataTransform).map((key, index) =>
          dataToShowInStatus.includes(key) ? (
            <Grid item xs={4}>
              <DisabledTextField
                label={dataToShowInStatusMapping[key]}
                labelValue={dataTransform[key]}
              />
            </Grid>
          ) : (
            ""
          )
        )}
      </Grid>

      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <CancelRoundedIcon className={classes.failedIcon} />
        <Typography display="inline">
          Your E-Mandate registration is rejected due to{" "}
          <Box display="inline" fontWeight="fontWeightBold">
            {dataTransform.reasonDesc}
          </Box>
        </Typography>
        {props.apiData.userType === "merchant" ? (
          <Typography>
            Do you want to create another E-Mandate{" "}
            <Link href={`/mandateRegistration/${generateUniqueId()}`}>
              {" "}
              Click here.
            </Link>
          </Typography>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
export default withRouter(MandateRegistrationFailed);

import { CircularProgress } from "@material-ui/core";
import React, { memo } from "react";
import MainLayout from "../../layout/MainLayout";
import Spinner from "./Spinner";

const Fallback = memo(() => {
  return (
    <MainLayout>
      <Spinner />
    </MainLayout>
  );
});

export default Fallback;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PopOver from "./PopOver";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

import CircularProgress from "@material-ui/core/CircularProgress";
import ShareIcon from "@material-ui/icons/Share";
import EditIcon from "@material-ui/icons/Edit";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import { useDispatch } from "react-redux";
import {
  sendGeneratedLinkOrMessageThroughMessage,
  sendGeneratedLinkOrMessageThroughEmail,
} from "../../store/onlineEmandate";
import customToast from "../../utilities/TostNotifications/tostTypes";

// Importing Formik and Yup for form creation and validation--
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../helper/Formik/FormControl/FormControl";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 75,
    transform: "translateX(0px)",
    flexGrow: 1,
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  textField: {
    width: "100%",
    padding: " 10px 15px",
    marginTop: "5px",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  helperText: {
    margin: "0px",
    padding: "0px",
    color: "red",
  },
}));

export default function ShareButtonFunctionality(props) {
  useEffect(() => {
    return () => {};
  }, []);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [helperText, setHelperText] = useState("");
  const [popoverEmailAnchorEl, setPopoverEmailAnchorEl] = React.useState(null);
  const [popoverMobileAnchorEl, setPopoverMobileAnchorEl] =
    React.useState(null);

  const handlePopoverMobileClick = (event) => {
    setPopoverMobileAnchorEl(event.currentTarget);
    setPopoverEmailAnchorEl(null);
  };

  const handlePopoverMobileClose = () => {
    setPopoverMobileAnchorEl(null);
  };

  const handlePopoverEmailClick = (event) => {
    setPopoverEmailAnchorEl(event.currentTarget);
    setPopoverMobileAnchorEl(null);
  };

  const handlePopoverEmailClose = () => {
    setPopoverEmailAnchorEl(null);
  };

  const openPopoverMobile = Boolean(popoverMobileAnchorEl);
  const popoverMobileId = openPopoverMobile ? "simple-popover" : undefined;

  const openPopoverEmail = Boolean(popoverEmailAnchorEl);
  const popoverEmilId = openPopoverEmail ? "simple-popover" : undefined;

  const validationFunction = (value, field) => {
    switch (field) {
      case "mobile":
        {
          setMobile(value);
          const mobileRegex = /[0=9]{10}/;
          if (value === "") {
            setError(true);
            setHelperText("Required!");
          }
          if (mobileRegex.test(value)) {
            setError(true);
            setHelperText("Invalid Mobile number!");
          }
        }
        break;
      case "email":
        {
          setEmail(value);
          const emailRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if (value === "") {
            setError(true);
            setHelperText("Required!");
          }
          if (emailRegex.test(value)) {
            setError(true);
            setHelperText("Invalid Email!");
          }
        }
        break;
      default:
        return null;
    }
  };

  const handleWhatsAppIconClick = () => {
    // alert("Whatsaap Send");
  };

  // onClick functions for cancel Mandate case
  const handleEmailOutlinedIconClickCustom = (value) => {
    setLoading(true);
    dispatch(
      sendGeneratedLinkOrMessageThroughEmail({
        toEmail: value,
        toCc: "",
        // subject: "Test Subject",
        subject: props.msgSubject,
        // msg: `Hello Welcome To SabPaisa here is Your  Mandate Link :${props.generatedLink}`,
        msg: props.msg,
      })
    )
      .then((res) => {
        setLoading(false);
        customToast.successToast("Email has been sent");
        handlePopoverEmailClose();
      })
      .catch((err) => {
        setLoading(false);
        customToast.errorToast("Something went wrong!");
        handlePopoverEmailClose();
      });
  };

  const handleMessageOutlinedIconClickCustom = (value) => {
    setLoading(true);
    dispatch(
      sendGeneratedLinkOrMessageThroughMessage({
        mobilenumber: value,
        // message: `Hello Welcome To SabPaisa here is Your Mandate Link :${props.generatedLink} `,
        message: props.msg,
      })
    )
      .then((res) => {
        setLoading(false);
        customToast.successToast("Message has been sent");
        handlePopoverMobileClose();
      })
      .catch((err) => {
        setLoading(false);
        customToast.errorToast("Something went wrong!");
        handlePopoverMobileClose();
      });
  };

  // onClick functions for normal cases
  const handleEmailOutlinedIconClick = () => {
    dispatch(
      sendGeneratedLinkOrMessageThroughEmail({
        toEmail: props.userDetails.payerEmail,
        toCc: "",
        // subject: "Test Subject",
        subject: props.msgSubject,
        // msg: `Hello Welcome To SabPaisa here is Your  Mandate Link :${props.generatedLink}`,
        msg: props.msg,
      })
    ).then((res) => {
      customToast.successToast("Email has been sent");
    });
  };

  const handleMessageOutlinedIconClick = () => {
    dispatch(
      sendGeneratedLinkOrMessageThroughMessage({
        mobilenumber: props.userDetails.payerMobile.split("-")[1],
        // message: `Hello Welcome To SabPaisa here is Your Mandate Link :${props.generatedLink} `,
        message: props.msg,
      })
    ).then((res) => {
      customToast.successToast("Message has been sent");
    });
  };

  const customActions = [
    {
      icon: (
        <>
          <EmailOutlinedIcon onClick={handlePopoverEmailClick} />
          <PopOver
            popoverId={popoverEmilId}
            popoverOpen={openPopoverEmail}
            popoverAnchorEl={popoverEmailAnchorEl}
            handlePopoverClose={handlePopoverEmailClose}
          >
            <Box p={2}>
              {/* <Typography></Typography> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .max(50, "Maximum allowed characters is 50")
                      .email("Please enter a valid email!")
                      .required("Please enter your email"),
                  })}
                  onSubmit={(values) => {
                    handleEmailOutlinedIconClickCustom(values.email);
                  }}
                >
                  {(formik) => (
                    <Form>
                      <FormikControl
                        control="input"
                        label="Please provide us the email address."
                        name="email"
                        type="email"
                        // pattern="[0-9]{10}"
                      />
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Send
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Box>
          </PopOver>
        </>
      ),
      name: "email",
    },
    {
      icon: (
        <>
          <MessageOutlinedIcon onClick={handlePopoverMobileClick} />
          <PopOver
            popoverId={popoverMobileId}
            popoverOpen={openPopoverMobile}
            popoverAnchorEl={popoverMobileAnchorEl}
            handlePopoverClose={handlePopoverMobileClose}
          >
            <Box p={2}>
              {/* <Typography>Please provide us the mobile number.</Typography> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Formik
                  initialValues={{
                    mobileNumber: {
                      countryCode: "91",
                      number: "",
                    },
                  }}
                  validationSchema={Yup.object({
                    mobileNumber: Yup.object().shape({
                      countryCode: Yup.number().nullable(),
                      number: Yup.string()
                        .nullable()
                        .required("Please enter your mobile number")
                        .matches(/[0-9]{10}/, "Invalid details"),
                    }),
                  })}
                  onSubmit={(values) => {
                    handleMessageOutlinedIconClickCustom(
                      values.mobileNumber.number
                    );
                  }}
                >
                  {(formik) => (
                    <Form>
                      <FormikControl
                        control="mobile-field"
                        label="Please provide us the mobile number."
                        name="mobileNumber"
                        name1="mobileNumber.countryCode"
                        name2="mobileNumber.number"
                        type="text"
                        field2MaxLength="10"
                        // pattern="[0-9]{10}"
                      />
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Send
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Box>
          </PopOver>
        </>
      ),
      name: "Message",
    },
  ];

  const actions = [
    // {
    //   icon: <WhatsAppIcon onClick={handleWhatsAppIconClick} />,
    //   name: "Whatsapp",
    // },
    {
      icon: <EmailOutlinedIcon onClick={handleEmailOutlinedIconClick} />,
      name: "email",
    },
    {
      icon: <MessageOutlinedIcon onClick={handleMessageOutlinedIconClick} />,
      name: "Message",
    },
    // { icon: <ShareIcon />, name: "Share" },
  ];

  // const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    // alert('Yooo')
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={classes.speedDial}
        icon={<ShareIcon openIcon={<EditIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="left"
      >
        {props.useCustomActions
          ? customActions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                placement="top"
                onClick={handleClose}
              />
            ))
          : actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                placement="bottom"
                onClick={handleClose}
              />
            ))}
      </SpeedDial>
    </div>
  );
}

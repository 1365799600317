import React from "react";
import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px"
  },
  label: {
    color: "gray",
    marginBottom: "10px",
    fontSize:'small'
  },
  signature: {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    height: "50px",
    background: "white",
    marginTop: "10px"
  }
}));

export default function PhysicalMandateSignatureField(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <input className={classes.signature} disabled />
      <lable className={classes.label}>{props.label}</lable>
    </div>
  );
}

import React from 'react';
import Popover from '@material-ui/core/Popover';

function PopOver(props) {
    return (
        <>
        <Popover
            id={props.popoverId}
            open={props.popoverOpen}
            anchorEl={props.popoverAnchorEl}
            onClose={props.handlePopoverClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            {props.children}
        </Popover>
        </>
    );
}

export default PopOver;
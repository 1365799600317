import { LinearProgress } from "@material-ui/core";
import React, { memo } from "react";

const Spinner = memo(() => {
  return (
    <div style={{ marginTop: "25px" }}>
      <LinearProgress />
    </div>
  );
});

export default Spinner;

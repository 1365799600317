import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import Button from '@material-ui/core/Button';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import DialogWrapper from "../../../view/Emandate/Mandate/components/DialogWrapper/DialogWrapper"
import ChangePasswordForm from './components/changePasswordForm/ChangePasswordForm'

import PopOver from '../../../helper/ReusableComponents/PopOver'

import IconButton from "@material-ui/core/IconButton";
import { Tooltip, Typography } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { withRouter } from "react-browser-router";
import { useDispatch, useSelector } from "react-redux";
import { userLoggedOut } from "../../../store/auth";


const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  profileIcon: {
    height: "fit-content",
    display: "flex",
    // flexDirection:'column',
    alignItems: "center",
    color:"rgba(77, 79, 78,1)"
  },
  userName:{marginRight:"8px",fontWeight:"bold",borderRight:"solid",paddingRight:"5px"},
}));

function CustomizedMenus(props) {
  const classes = useStyles();

  //--------------Popover control--------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen=(e)=>{
    setAnchorEl(e.currentTarget)
  }
  const handlePopoverClose=()=>{
    setAnchorEl(null)
  }   
  
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;
  //--------------------------------------------------

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);

 //---------------Dialog Wrapper control---------------
  const [openChangePasswordDialog,setChangePasswordDialog]=React.useState(false)

  const handleCloseChangePasswordDialog=()=>setChangePasswordDialog(false)

  const handleOpenChangePasswordDialog=()=>{
    setAnchorEl(null)
    setChangePasswordDialog(true)
  }
 //---------------------------------------------------

  const onClickLogout = () => {
    dispatch(userLoggedOut());
    // if (currentUser.user_first_name.toLowerCase() === "Admin".toLowerCase()) {
    //   props.history.push("/adminLogin");
    // } else {
    //   props.history.push("/login");
    // }
    props.history.push("/login");
  };

  return (
    <div>
      
        <div className={classes.profileIcon} onClick={handlePopoverOpen} style={{}}>
          <PersonIcon fontSize="large"/>
          <Typography variant="caption" className={classes.userName }>
            {/* {currentUser.user_first_name || "Admin"} */}
            {currentUser.user_first_name}
          </Typography>
          <Tooltip title="Logout">
            <ExitToAppIcon fontSize="large" onClick={onClickLogout}  />
            </Tooltip>  
         
        </div>
        <PopOver
            popoverId={popoverId}
            popoverOpen={openPopover}
            popoverAnchorEl={anchorEl}
            handlePopoverClose={handlePopoverClose}
          >
            <div style={{padding:"10px"}}>
              <Button onClick={handleOpenChangePasswordDialog}>Change Password</Button>
              {/* <Typography onClick={handleOpenChangePasswordDialog}>Change Password</Typography> */}
            </div>
          </PopOver>

          <DialogWrapper
            title="Change Password"
            open={openChangePasswordDialog}
            handleClose={handleCloseChangePasswordDialog}
          >
            <ChangePasswordForm user_id={currentUser.user_id} handleCloseChangePasswordDialog={handleCloseChangePasswordDialog}/>
          </DialogWrapper>
     
      {/* <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={() => {
            onClickLogout();
          }}
        >
          <ListItemIcon> */}
          
          {/* </ListItemIcon>
          <ListItemText primary="Logout" />
        </StyledMenuItem>
      </StyledMenu> */}
    </div>
  );
}

export default withRouter(CustomizedMenus);

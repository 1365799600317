import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1976d2",
      light: "#2196f3",
    },
    loginpage: {
      main: "#b3d6f3",
    },
  },
  // shape: {
  //   borderRadius: 30,
  // },
  overrides: {
    MuiInputLabel: {},

    MuiInputBase: {
      input: {
        fontSize: "small",
      },
    },
    MuiTextField: {
      variant: "outlined",
      root: {},
    },
    MuiButton: {
      palette: {},
      root: {
        textTransform: "none",
        borderRadius: "50px",
      },

      // fullWidth: {
      //   maxWidth: "50px",
      //   borderRadius: "300px",
      // },
    },

    MuiFormLabel: {
      root: {
        fontSize: "18px",
        color: "black",
      },
    },

    MuiListItem: {
      root: {
        padding: "10px",
        "&$selected": {
          backgroundColor: "#2196f3",
          color: "white",
          borderRadius: "0px 30px 30px 0px",
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "#2196f3",
          color: "white",
          borderRadius: "0px 30px 30px 0px",
        },
      },
    },
  },

  props: {
    MuiTextField: {
      InputLabelProps: {
        shrink: true,
      },
    },
  },
});

export const otherTheme = createMuiTheme({});

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";

const TransactionsEnquiry = {
  TransactionsEnquiryData: {
    payeeName: "",
    clientId: "",
    transCompleteDate: "",
    emiAmount: "",
    paymentMode: "",
    clientName: "",
    clientCode: "",
    transDate: "",
    payeeMobile: "",
    payeeMail: "",
    txnId: "",
    status: "",
    message: "",
  },
};

// viewSelected Slice----
export const TransactionsEnquiryData = createAsyncThunk(
  "Settlement/TransactionsEnquiryData",
  async (requestParam) => {
    const data = new FormData();
    data.append("transactionId", requestParam);
    const response = await Axios.post(
      `${config.backendUrl}/npci/getTransactionDetailById`,
      data
    );

    return response.data;
  }
);

const TransactionsEnquirySlice = createSlice({
  name: "TransactionsEnquiry",
  initialState: TransactionsEnquiry,
  reducers: {
    clearTransactionsEnquiry: (Settelments, action) => {
      Settelments.TransactionsEnquiryData = {
        payeeName: "",
        clientId: "",
        transCompleteDate: "",
        emiAmount: "",
        paymentMode: "",
        clientName: "",
        clientCode: "",
        transDate: "",
        payeeMobile: "",
        payeeMail: "",
        txnId: "",
        status: "",
        message: "",
      };
    },
  },

  extraReducers: {
    [TransactionsEnquiryData.pending]: (Settelments, action) => {
      Settelments.status = "pending";
    },
    [TransactionsEnquiryData.fulfilled]: (Settelments, action) => {
      Settelments.TransactionsEnquiryData = action.payload;
    },
    [TransactionsEnquiryData.rejected]: (Settelments, action) => {
      Settelments.status = "failed";
      Settelments.error = action.error.message;
    },
  },
});

export const { clearTransactionsEnquiry } = TransactionsEnquirySlice.actions;
// Exporting slice reducer
export default TransactionsEnquirySlice.reducer;

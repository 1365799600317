import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

const mandatePayment = {};

export const fetchRandomApi = createAsyncThunk(
  "selectedView/fetchRandomApi",
  async () => {
    const response = await Axios.get(
      "https://jsonplaceholder.typicode.com/posts"
    );
    return response.data;
  }
);

export const makePaymentViaSabpaisa = createAsyncThunk(
  "mandatePayment/makePaymentViaSabpaisa",
  async (requestParam) => {
    const response = await Axios.get(
      "https://jsonplaceholder.typicode.com/posts"
    );
    return response.data;
  }
);
// viewSelected Slice----

const mandatePaymentSlice = createSlice({
  name: "mandatePayment",
  initialState: mandatePayment,
  reducers: {},
  extraReducers: {},
});

// Exporting uiState actions
export const {} = mandatePaymentSlice.actions;

// Exporting slice reducer
export default mandatePaymentSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../config";
import toastConfig from "../utilities/TostNotifications/tostTypes";

const activitiesStore = {
  uploadMandateList: [],
  isLoading: false,
  uploadOption: false,
  updateOption: false,
  // xlUploadedDialog:false,
  uploadResponse: "",
  updateResponse: "",
  xlFileNamesList: [],
  xlFileContentList: [],
  currentView: "",
};

export const fetchUploadMandateList = createAsyncThunk(
  "activityUpolad/fetchUploadMandateList",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/npci/filterRegistration`,
      requestParam
    );
    return response.data;
  }
);

export const exportExcel = createAsyncThunk(
  "activityUpolad/exportExcel",
  async (requestParam) => {
    const response = await Axios.post(
      `${config.backendUrl}/REST/viewAndUploadXL`,
      requestParam
    );
    return response.data;
  }
);

export const fetchXLFileNames = createAsyncThunk(
  "activityUpdate/fetchXLFileNames",
  async () => {
    const response = await Axios.get(`${config.backendUrl}/REST/ReadFileList`);
    return response.data;
  }
);

export const fetchXLFileContent = createAsyncThunk(
  "activityUpdate/fetchXLFileContent",
  async (requestParam) => {
    const response = await Axios.get(
      `${config.backendUrl}/REST/ReadXLFiles/${requestParam}`
    );
    return response.data;
  }
);

export const updateTransaction = createAsyncThunk(
  "activityUpdate/updateTransaction",
  async (requestParam) => {
    const response = await Axios.post(
      // `${config.backendUrl}/npci/updateTransaction/${requestParam}`,
      `${config.backendUrl}/npci/updateTransaction/363411602211134749`,
      {
        paymentStatus: "SUCCESS",
        statusreasoncode: "0000",
        divisioncode: "1234",
        bankReferenceNo: "dsafwsefs",
        additionalInfo: "NA",
        remarks: "ok",
      }
    );
    return response.data;
  }
);

const activitiesSlice = createSlice({
  name: "activitiesStore",
  initialState: activitiesStore,
  reducers: {
    setLoading: (store, action) => {
      store.isLoading = action.payload.loading;
    },
    setUpload: (store, action) => {
      store.uploadOption = action.payload.upload;
    },
    setUpdate: (store, action) => {
      store.updateOption = action.payload.update;
    },
    setCurrentView: (store, action) => {
      store.currentView = action.payload.view;
    },
    resetUploadMandateList: (store) => {
      store.uploadMandateList = [];
    },
  },
  extraReducers: {
    //fetchUploadMandateList
    [exportExcel.pending]: (state, action) => {
      state.status = "pending";
    },
    [exportExcel.fulfilled]: (state, action) => {
      state.uploadResponse = action.payload.resp;
      // state.isLoading=false
      // state.xlUploadedDialog=false
      // alert(`File Upload: ${state.uploadResponse}`)
      toastConfig.successToast(`File Upload Status: ${state.uploadResponse}`);
    },
    [exportExcel.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    //fetchUploadMandateList
    [fetchUploadMandateList.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchUploadMandateList.fulfilled]: (state, action) => {
      state.uploadMandateList = action.payload;
      state.isLoading = false;
    },
    [fetchUploadMandateList.rejected]: (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.error = action.error.message;
    },

    //fetchXLFileNames
    [fetchXLFileNames.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchXLFileNames.fulfilled]: (state, action) => {
      // state.xlFileNamesList = action.payload;
      state.xlFileNamesList = action.payload;
      state.isLoading = false;
    },
    [fetchXLFileNames.rejected]: (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.error = action.error.message;
    },

    //fetchXLFileContent
    [fetchXLFileContent.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchXLFileContent.fulfilled]: (state, action) => {
      state.xlFileContentList = action.payload;
      state.isLoading = false;
    },
    [fetchXLFileContent.rejected]: (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.error = action.error.message;
    },

    //updateTransaction
    [updateTransaction.pending]: (state, action) => {
      state.status = "pending";
    },
    [updateTransaction.fulfilled]: (state, action) => {
      state.updateResponse = action.payload;
      // alert(`Transaction Updated`)
      toastConfig.successToast(`Transaction Updated Successfully`);
      // state.isLoading=false
    },
    [updateTransaction.rejected]: (state, action) => {
      state.status = "failed";
      // state.isLoading=false
      state.error = action.error.message;
    },
  },
});

export const {
  setLoading,
  setUpload,
  setCurrentView,
  setUpdate,
  resetUploadMandateList,
} = activitiesSlice.actions;

// Exporting slice reducer
export default activitiesSlice.reducer;
